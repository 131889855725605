import React, { useState } from 'react';
import { Box, CircularProgress, Grid, IconButton, TextField, Typography, useTheme } from '@mui/material';
import CustomButton from '../button/CustomButton';
import { useParams } from 'react-router-dom';
import { useUploadDataMutation } from '../../api/apiSliceV2';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';


interface FileUploadButtonProps {
    _id: string;
    startId: string;
    tagName: string;
    documentTypes: { value: string, label: string }[];
}

enum DocumentType {
  ITEM = 'Item Document',
  START = 'Start Document',
  BUDGET = 'Budget Document',
  PROPOSAL = 'Proposal Document',
  CONTRACT = 'Contract Document'
}

type EntityIdMap = {
  _id: DocumentType.ITEM;
  start_id: DocumentType.START;
  budget_id: DocumentType.BUDGET;
  proposal_id: DocumentType.PROPOSAL;
  contract_id: DocumentType.CONTRACT;
};

const getDocumentType = (ids: Partial<Record<keyof EntityIdMap, string>>): DocumentType | null => {
  const typeMap: EntityIdMap = {
    _id: DocumentType.ITEM,
    start_id: DocumentType.START,
    budget_id: DocumentType.BUDGET,
    proposal_id: DocumentType.PROPOSAL,
    contract_id: DocumentType.CONTRACT
  };

  for (const [key, value] of Object.entries(ids)) {
    if (value && key in typeMap) {
      return typeMap[key as keyof EntityIdMap];
    }
  }
  return null;
};

const FileUploadButton: React.FC<FileUploadButtonProps> = (props: FileUploadButtonProps) => {
    const { _id, startId, tagName, documentTypes } = props;
    const { project_id, budget_id, proposal_id, contract_id, start_id } = useParams();
    const [idType, setIdType] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const theme = useTheme();
    const [uploadData, { isLoading }] = useUploadDataMutation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            await uploadFile(file);
        }
    };

    const handleMenuItemClick = (value: string) => {
        setIdType(value);
    };
    

    const uploadFile = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const ids = {
                _id,
                start_id,
                budget_id,
                proposal_id,
                contract_id
            };

            const documentType = getDocumentType(ids);
            if (documentType) {
                formData.append('documentType', documentType);
            }

            // Add all relevant IDs to formData
            Object.entries(ids).forEach(([key, value]) => {
                if (value) formData.append(key, value);
            });

            // Add start ID from props if available
            if (startId) {
                formData.append('start_id', start_id ? start_id : startId);
            }

            // Add project ID if available
            if (project_id) {
                formData.append('project_id', project_id);
            }

            // Add idType if available (for start document types)
            if (documentTypes.length > 0) {  // If documentTypes are provided
                formData.append('idType', idType);  // Send the selected idType directly
            }

            if (link) {
                formData.append('link', link);
            }

            await uploadData({
                tagName,
                body: formData,
                url: `/documents`
            }).unwrap();
            
            setLink("");
            setIdType("");
            handleClose();
        } catch (error: any) {
            console.error('File upload failed:', error);
        }
    };

    return (
        <Grid container>
            <input
                type="file"
                id="file-input"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <CustomButton
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleClick}
                disabled={isLoading}
                sx={{border: `2px solid ${theme.palette.primary.main}`}}
            >
                {isLoading ? (
                    <CircularProgress 
                        size={24} 
                        sx={{width: '24px', height: '24px'}} 
                        thickness={7.2} 
                        disableShrink 
                        color="primary" 
                    /> 
                ) : 'Upload'}
            </CustomButton>
            
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    minWidth: 200,
                    maxHeight: '40vh'
                }}
            >
                <Grid container>
                    {!idType ?
                        (<Grid item xs={12}>
                            <Box sx={{ px: 2, py: 1 }}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Select ID Type
                                </Typography>
                            </Box>
                            {documentTypes && documentTypes.map((type) => (
                                <MenuItem 
                                    key={type.value} 
                                    onClick={() => handleMenuItemClick(type.value)}
                                >
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Grid>
                        ):(
                            <Grid item xs={12} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{p: 2}}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    {idType}
                                </Typography>
                                <IconButton
                                    aria-label="remove"
                                size="small"
                                onClick={() => setIdType("")}
                                sx={{marginBottom: 2}}
                            >
                                <ClearSharpIcon fontSize='small' />
                                </IconButton>
                            </Grid>
                        )
                    }
                        <Grid item xs={12} sx={{ p: 2 }}>
                            <TextField
                                label="Enter Link (optional)"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                size="small"
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <CustomButton
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleClose();
                                    document.getElementById('file-input')?.click();
                                }}
                                fullWidth
                            >
                                Select File
                            </CustomButton>
                        </Grid>
                </Grid>
            </Menu>
        </Grid>
    );
};

export default FileUploadButton;
