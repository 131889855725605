import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CustomButton from "../button/CustomButton";

interface ConfirmActionProps {
  title: string;
  message: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmAction = ({ title, message, open, onConfirm, onCancel }: ConfirmActionProps) => {
  return (
    <Dialog 
      open={open} 
      onClose={onCancel}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: "white",
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        }
      }}
    > 
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <CustomButton 
          onClick={onConfirm} 
          size="small" 
          color="success" 
          variant="contained"
          sx={{
            color: "white",
            fontWeight: "bold"
          }}
        >
          Confirm
        </CustomButton>
        <CustomButton 
          onClick={onCancel} 
          size="small" 
          color="error" 
          variant="contained"
          sx={{
            color: "white",
            fontWeight: "bold"
          }}
        >
          Cancel
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAction;