import CustomButton from "../../components/button/CustomButton";
import { useUpdateDataMutation } from "../../api/apiSliceV2";
import { ActionType } from "./ActionType";
import ConfirmAction from "../../components/modals/ConfirmAction";
import { useState } from "react";
const FulFillOrder: React.FC<ActionType> = (props:any) => {
    const {row, tagName} = props;
    const [updateData] = useUpdateDataMutation();
    const [open, setOpen] = useState<boolean>(false);

    const handleStatusUpdate = async () => {
      console.log("handleStatusUpdate")
      try {
        const requestData = {
          ...row,
          order_status: "FulFilled"
        };
        await updateData({
            tagName,
            url: `/warehouse/orders?id=${row?._id}`,
            body: requestData,
        }).unwrap();
        setOpen(false);
      } catch (error:any) {
        console.log(error)
      }
    }
    return (
        <>
          <CustomButton disabled={row?.order_status === 'FULFILLED'} size="small" color="success" variant="contained" sx={{color: "white"}} onClick={() => setOpen(true)}>FulFill Order</CustomButton>
          <ConfirmAction title="Fulfill Order" message={`Are you sure you want to fulfill this order?\nOnce an order is fulfilled, Supervisors will no longer be able to edit the order.`} onConfirm={handleStatusUpdate} onCancel={() => setOpen(false)} open={open} />
        </>
    );
}

export default FulFillOrder;