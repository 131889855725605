import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme, size, color, variant }) => ({
  fontWeight: 800,
  color: color || theme.palette.primary.main,
  padding: size === 'large' ? '12px 24px' : '8px 16px',
  backgroundColor: variant === 'text' ? 'transparent' : (color || theme.palette.primary.main),
  border: variant === 'text' ? 'none' : `1px solid rgba(0, 0, 0, 0.1)`,
  '&:hover': {
    backgroundColor: variant === 'text' 
      ? 'rgba(0, 0, 0, 0.04)' 
      : theme.palette.primary.dark,
  },
}));

function CustomButton({ children, onClick, size, color, variant = 'contained', ...props }) {
  return (
    <StyledButton
      size={size}
      color={color}
      variant={variant}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledButton>
  );
}

export default CustomButton;
