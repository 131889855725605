import { useAppDispatch } from "../../../app/hooks";
import { getOrdProcEnt as getOrdProcEntAction } from "../../reports/reportSlice";
import { useCallback } from "react";

interface OrdProcEntRequest {
    fileName: string;
    url?: string;
    body?: {
        order_ids: string[];
    };
}

const useOrdProcEnt = (): {
    fetchOrdProcEnt: (orderIds: string[], orderNumber: string) => void;
} => {
    const dispatch = useAppDispatch();

    const fetchOrdProcEnt = useCallback((orderIds: string[], orderNumber: string) => {
        const request: OrdProcEntRequest = orderIds.length > 1
            ? {
                fileName: 'ORDPROCENT EXPORT',
                body: {
                    order_ids: orderIds
                }
            }
            : {
                fileName: `ORDER-${orderNumber}-ORDPROCENT`,
                url: `order_id=${orderIds[0]}`
            };

        dispatch(getOrdProcEntAction(request));
    }, [dispatch]);

    return { fetchOrdProcEnt };
};

export default useOrdProcEnt;