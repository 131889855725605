import { useEffect, useMemo, useState } from 'react';
import { 
    TextField, 
    List, 
    ListItemText, 
    Box, 
    ListItemButton} from '@mui/material';
import { useGetDataQuery } from '../../../api/apiSliceV2';
import { apiRouteMap } from '../../../routes/urls';


const WarehouseSearch = ({ handleAddMaterial }: any) => {
    const [query, setQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState<any>([]);
    const [selectedParts, setSelectedParts] = useState<any>([]);
    const queryParams = useMemo(() => ({ url: apiRouteMap.get('/warehouse-materials').url }), [apiRouteMap]);
    const {data: items} = useGetDataQuery(queryParams);

    useEffect(() => {
        if (items?.data) {
            setFilteredItems(items?.data?.filter((item:any) => !item.default));
        }

        return () => {
            setQuery("");
            setFilteredItems([]);
            setSelectedParts([]);
        }
    }, [items?.data]);

    const handleSearch = (event:any) => {
        const searchQuery = event.target.value?.toLowerCase();
        const formatted = searchQuery?.replace(/\s+/g, ''); // Remove spaces from search query

        setQuery(formatted);

        const filtered = items?.data?.filter((item:any) =>
            `${item.part_number}${(item.part_description).replace(/\s+/g, '')}`.toLowerCase().includes(searchQuery)
        );
        setFilteredItems(filtered);
    };

    return (
        <Box sx={{ p: 2 }}>
            {/* Search Input */}
            <TextField
                fullWidth
                size="small"
                placeholder="Search materials..."
                value={query}
                onChange={handleSearch}
                sx={{ mb: 2 }}
            />

            {/* Search Results */}
            <List dense sx={{ maxHeight: '400px', overflow: 'auto' }}>
                {filteredItems.map((item: any) => (
                    <ListItemButton
                        key={item._id}
                        onClick={() => handleAddMaterial(item)}
                        disabled={selectedParts.some((p: any) => p._id === item._id)}
                        dense
                    >
                        <ListItemText
                            primary={item.part_number}
                            secondary={item.part_description}
                        />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );
};

export default WarehouseSearch;
