import CustomButton from "./CustomButton";

interface ExpandAllButtonProps {
    expandedRowIds: string[];
    rows: any[];
    setExpandedRowIds: (ids: string[]) => void;
}

const ExpandAllButton: React.FC<ExpandAllButtonProps> = ({
    expandedRowIds,
    rows,
    setExpandedRowIds
}) => {
    const handleClick = () => {
        const allIds = rows.map(row => row._id || row.id);
        setExpandedRowIds(expandedRowIds.length === rows.length ? [] : allIds);
    };

    return (
        <CustomButton
            onClick={handleClick}
            size="small"
            color="primary"
            variant="text"
        >
            {expandedRowIds.length === rows.length ? 'Collapse All' : 'Expand All'}
        </CustomButton>
    );
};

export default ExpandAllButton;     