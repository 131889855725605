import { useMemo, useState } from 'react';
import ConfirmRemoveAll from '../../dialog/ConfirmRemoveAll';
import DynamicStepper from '../../stepper/Stepper';
import ExpandAllButton from '../../button/ExpandAllButton';
import CustomButton from '../../button/CustomButton';
import withCustomGridToolbar from './withCustomGridToolbar';
import Grid from '@mui/material/Grid';

export interface CustomGridToolbarProps {
  context?: any;
  handleDeleteAll?: () => void;
  selectedRows?: string[];
  options: {
    showHandleDeleteAll: boolean;
    showStageOrders: boolean;
    showExpandAll: boolean;
  } & Record<string, boolean>;
  expandedRowIds?: string[];
  rows?: any[];
  setExpandedRowIds?: (expandedRowIds: string[]) => void;
}

const CustomGridToolbar = (props: CustomGridToolbarProps) => {
  const { context, handleDeleteAll, options } = props;
  const [open, setOpen] = useState<boolean>(false);

  // Define toolbar actions
  const toolbarActions = useMemo(() => [
    // Stepper Action
    context?.steps?.length > 0 && {
      key: 'stepper',
      component: 
        <DynamicStepper 
          context={context} 
          {...props} 
        />,
      order: 1
    },
     
    // Expand All Action
    options?.showExpandAll && {
      key: 'expandAll',
      component: (
        <ExpandAllButton 
          expandedRowIds={props.expandedRowIds || []}
          rows={props.rows || []}
          setExpandedRowIds={props.setExpandedRowIds || (() => {})}
        />
      ),
      order: 2
    },
    
    // Delete All Action
    options?.showHandleDeleteAll && handleDeleteAll && {
      key: 'deleteAll',
      component: (
        <>
          <CustomButton
            color="error"
            variant="text"
            onClick={() => setOpen(true)}
            size="small"
          >
            Delete All
          </CustomButton>
          <ConfirmRemoveAll
            open={open}
            setOpen={setOpen}
            handleDelete={handleDeleteAll}
          />
        </>
      ),
      order: 3
    }
  ], [context, options, open]);

  // Create enhanced toolbar with actions
  const EnhancedToolbar = useMemo(() => 
    withCustomGridToolbar(toolbarActions), 
  [toolbarActions, open]);
  return (
    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-start', padding: 1}}>
      <EnhancedToolbar {...props} />
    </Grid>
  );
};

export default CustomGridToolbar;
