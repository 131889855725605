import { GridColDef } from "@mui/x-data-grid-premium";
import metadataColumns from "../../components/columns/metadataColumns";
const jobTypeColumns:GridColDef[] = [
    {
        field: 'job_type_number',
        headerName: 'Job Type Number',
        type: 'string',
        width: 80,
        filterable: true,
        editable: true,
    },
    {
        field: 'job_type_description',
        headerName: 'Job Type Description',
        type: 'string',
        width: 220,
        filterable: true,
        editable: true,
    },
    {
        field: 'construction_type',
        headerName: 'Construction Type',
        type: 'string',
        width: 220,
        filterable: true,
        editable: true,
    },
    {
        field: 'contract_type',
        headerName: 'Contract Type',
        type: 'string',
        width: 200,
        filterable: true,
        editable: true,
    },
    ...metadataColumns
];

export default jobTypeColumns;