import { useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { TagTypes } from "../../api/tagTypes";
import CustomGridToolbar from "../../components/grid/toolbars/CustomGridToolbar";
import { DetailConfig } from "../../components/interfaces/DetailConfig";
import actions from "../actions/actions";
import customerColumns from "../columns/customerColumns";
import useDivisionColumns from "../columns/useDivisionColumns";
import tradeColumns from "../columns/tradeColumns";

const useCompanyDetailConfig = () => {
    const {company, division} = useAppSelector((state:any) => state.auth);
    const divColumns = useDivisionColumns();

    const tabs:DetailConfig[] = useMemo(() => {
        const cols:DetailConfig[] = [  
            {
                route: "/divisions",
                tagType: TagTypes.DIVISIONS,
                index: 0,
                label: 'Division',
                steps: actions?.createDivision,
                header: undefined,
                detail: {
                    columns: divColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                params: undefined
            },
            { 
                route: "/customers",
                tagType: TagTypes.CUSTOMERS,
                params: {company_id: company?._id},
                index: 1,
                label: 'Customers',
                steps: actions?.createCustomer,
                header: undefined,
                detail: {
                    columns: customerColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
            { 
                route: "/trades",
                tagType: TagTypes.TRADES,
                params: {division_id: division?._id},
                index: 2,
                label: 'Trades',
                steps: actions?.createTrade,
                header: undefined,
                detail: {
                    columns: tradeColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
     
        ];
        return cols;    

    }, [divColumns]);

    return {
        tabs,
    };
};

export default useCompanyDetailConfig;