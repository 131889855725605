import { Autocomplete, TextField } from '@mui/material';
import { useMemo } from 'react';
import { useGetDataQuery } from '../../api/apiSliceV2';  
import { useAppSelector } from '../../../app/hooks';
import { GridRenderEditCellParams,  GridColDef} from '@mui/x-data-grid-premium';
import metadataColumns from "../../components/columns/metadataColumns";

const useCostCodeColumns = (): GridColDef[] => {
  const division = useAppSelector((state:any) => state.auth);
  const params = useMemo(() => ({
    division_id: division?._id
  }),[division]);
  const { data: segmentOneData } = useGetDataQuery({ url: `/cost-code-segment-one`, params, tagName: `/cost-code-segment-one-${division._id}`});
  const { data: segmentTwoData } = useGetDataQuery({ url: `/cost-code-segment-two`, params, tagName: `/cost-code-segment-two-${division._id}` });
  const { data: costTypesData } = useGetDataQuery({ url: `/cost-types`, params, tagName: `/cost-types-${division._id}`});

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'cost_type_id',
      headerName: 'Cost Type',
      type: 'string',
      width: 120,
      filterable: true,
      editable: true,
      valueGetter: (value:any) => value?.cost_type || '',
      renderEditCell: (params: any) => (
        <Autocomplete
          value={params.value || ""}
          onChange={(event, newValue) => {
            const selectedCostType = costTypesData?.data?.find(
              (costType: any) => costType.cost_type === newValue
            );
            params.api.setEditCellValue({ id: params.id, field: 'cost_type_id', value: selectedCostType?._id }, event);
            params.api.setEditCellValue({ id: params.id, field: 'segment_one_id', value: params?.row?.segment_one_id?._id }, event);
            params.api.setEditCellValue({ id: params.id, field: 'segment_two_id', value: params?.row?.segment_two_id?._id }, event);
          }}
          options={costTypesData?.data?.map((costType: any) => costType.cost_type) || []}
          getOptionKey={(option) => option._id}
          renderInput={(params) => <TextField {...params} />}
        />
      ),
    },
    {
      field: 'cost_type_description',
      headerName: 'Cost Type Description',
      type: 'string',
      width: 220,
      filterable: true,
      editable: false,
      valueGetter: (_value:any, row:any) => {
        return row?.cost_type_id?.description || ''},
    },
    {
      field: 'segment_one_id',
      headerName: 'Seg One',
      type: 'string',
      width: 120,
      filterable: true,
      editable: true,
      valueGetter: (value:any) => value?.segment_one || '',
      renderEditCell: (params: any) => (
        <Autocomplete
          value={params.value || ""}
          fullWidth
          onChange={(event, newValue) => {
            const selectedSegmentOne = segmentOneData?.data?.find(
              (segment: any) => segment.segment_one === newValue
            );
            params.api.setEditCellValue({ id: params.id, field: 'segment_one_id', value: selectedSegmentOne?._id }, event);
            params.api.setEditCellValue({ id: params.id, field: 'segment_two_id', value: params?.row?.segment_two_id?._id }, event);
            params.api.setEditCellValue({ id: params.id, field: 'cost_type_id', value: params?.row?.cost_type_id?._id }, event);
          }}
          options={segmentOneData?.data?.map((segment: any) => segment.segment_one) || []}
          getOptionKey={(option) => option._id}
          renderInput={(params) => <TextField {...params} />}
        />
      ),
    },
    {
      field: 'segment_one_description',
      headerName: 'Seg One Description',
      type: 'string',
      width: 220,
      filterable: true,
      editable: false,
      valueGetter: (_value: any, row: any) => {
        return row?.segment_one_id?.description || ''},
    },
    {
      field: 'segment_two_id',
      headerName: 'Seg Two',
      type: 'string',
      width: 120,
      filterable: true,
      editable: true,
      valueGetter: (_value: any, row: any) => row?.segment_two_id?.segment_two,
      renderEditCell: (params: GridRenderEditCellParams) => {
        console.log(params)
        return (
        <Autocomplete
          value={params.value || ""}
          onChange={(event, newValue) => {
            const selectedSegmentTwo = segmentTwoData?.data?.find(
              (segment: any) => segment.segment_two === newValue
            );
            console.log(newValue, selectedSegmentTwo)
            params.api.setEditCellValue({ id: params.id, field: 'segment_two_id', value: selectedSegmentTwo?._id }, event);
            params.api.setEditCellValue({ id: params.id, field: 'segment_one_id', value: params?.row?.segment_one_id?._id }, event);
            params.api.setEditCellValue({ id: params.id, field: 'cost_type_id', value: params?.row?.cost_type_id?._id }, event);
          }}
          options={segmentTwoData?.data?.map((segment: any) => segment.segment_two) || []}
          getOptionKey={(option) => option._id}
          renderInput={(params) => <TextField {...params} />}
        />
      )},
    },
    {
      field: 'segment_two_description',
      headerName: 'Seg Two Description',
      type: 'string',
      width: 220,
      filterable: true,
      editable: false,
      valueGetter: (_value: any, row: any) => row?.segment_two_id?.description,
    },
    {
      field: 'cost_code_id',
      headerName: 'Cost Code',
      type: 'string',
      width: 120,
      filterable: true,
      editable: false,
      valueGetter: (_value: any, row: any) => `${row?.segment_one_id?.segment_one}.${row?.segment_two_id?.segment_two}`,
    },
    {
      field: 'cost_code_description',
      headerName: 'Cost Code Description',
      type: 'string',
      width: 220,
      filterable: true,
      editable: false,
      valueGetter: (_value: any, row: any) => `${row?.segment_one_id?.description} ${row?.segment_two_id?.description}`,
    },
    ...metadataColumns
  ], [costTypesData, segmentOneData, segmentTwoData, division]);

  return columns;
};

export default useCostCodeColumns;
