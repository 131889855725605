import { GridColDef } from "@mui/x-data-grid-premium";
import { WIDTHS } from "../../../utils/columnUtil";


export const creationColumns:GridColDef[] = [
  {
    field: 'created_at',
    headerName: 'Created At',
    headerAlign: "left",
    align: "left",
    type: 'date',
    width: WIDTHS.MEDIUM,
    filterable: true,
    editable: false,
    valueGetter: (value:any, row:any) => {
      const createdAt = value || row?.metadata?.created_at;
      return createdAt ? new Date(createdAt) : undefined
    },
    valueFormatter: (value:any) => {
      if (!value) {
        return '';
      }
      const date = new Date(value);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: WIDTHS.MEDIUM,
    filterable: true,
    editable: false,
    valueGetter: (value:any, row:any) => value || row?.metadata?.created_by,
  },
];

export const updateColumns:GridColDef[] = [
  {
    field: 'updated_at',
    headerName: 'Updated At',
    headerAlign: "left",
    align: "left",
    type: 'date',
    width: WIDTHS.MEDIUM,
    filterable: true,
    editable: false,
    valueGetter: (value:any) => value ? new Date(value) : undefined,
    valueFormatter: (value:any) => {
      if (!value) {
        return '';
      }
      const date = new Date(value);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  },
  {
    field: 'updated_by',
    headerName: 'Updated By',
    headerAlign: "left",
    align: "left",
    type: 'string',
    width: WIDTHS.MEDIUM,
    filterable: true,
    editable: false,
    valueGetter: (value:any, row:any) => value || row?.metadata?.updated_by,
  }
];

const metadataColumns = [...creationColumns, ...updateColumns];
export default metadataColumns;
