export enum ProjectStatus {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    INACTIVE = 'INACTIVE',
    COMPLETED = 'COMPLETED'
  }
  
  export interface StatusConfig {
    value: ProjectStatus;
    color: string;
    label: string;
  }
  
  export const STATUS_MAP: Record<ProjectStatus, StatusConfig> = {
    [ProjectStatus.ACTIVE]: {
      value: ProjectStatus.ACTIVE,
      color: 'green',
      label: 'ACTIVE'
    },
    [ProjectStatus.PENDING]: {
      value: ProjectStatus.PENDING,
      color: 'orange',
      label: 'PENDING'
    },
    [ProjectStatus.INACTIVE]: {
      value: ProjectStatus.INACTIVE,
      color: 'red',
      label: 'INACTIVE'
    },
    [ProjectStatus.COMPLETED]: {
      value: ProjectStatus.COMPLETED,
      color: '#0074D9',  
      label: 'COMPLETED'
    }
  };