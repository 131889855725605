import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, ListItemText, Box, InputAdornment } from '@mui/material';
import CustomButton from '../button/CustomButton';

interface SelectMenuProps {
  options: string[];
  selectedValue: string;
  label: string;
  onChange: (selected: string) => void;
  onApply: () => void; // Handler for the apply button
}

const SelectMenu: React.FC<SelectMenuProps> = ({ options, label, selectedValue, onChange, onApply }) => {
  const labelId = `${label.replace(/\s+/g, '-').toLowerCase()}-label`;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: "40px" }}>
      <FormControl sx={{ minWidth: "128px"}}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          value={selectedValue}
          onChange={(event) => onChange(event.target.value as string)}
          renderValue={(selected) => selected}
          size="small"
          label={label}
          variant="outlined"
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 1 }}>
              <CustomButton
                variant="text"
                color="primary"
                size="small"
                onClick={(_e: any) => {
                  // /e.stopPropagation(); // Prevent dropdown from opening
                  onApply();
                }}
              >
                Apply
              </CustomButton>
            </InputAdornment>
          }
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: "40vh",
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectMenu;
