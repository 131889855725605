import { Grid } from '@mui/material';
import React from 'react';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium';

export interface ToolbarAction {
  key: string;
  component: React.ReactNode;
  order?: number;
}

export interface BaseToolbarOptions {
  showQuickFilter?: boolean;
  showPrintOptions?: boolean;
  showColumnsButton?: boolean;
  showHandleDeleteAll?: boolean;
  showStageOrders?: boolean;
  showExpandAll?: boolean;
}

export interface WithCustomGridToolbarProps {
  context?: any;
  handleDeleteAll?: () => void;
  selectedRows?: string[];
  options: BaseToolbarOptions & Record<string, boolean>;
  expandedRowIds?: string[];
  rows?: any[];
  setExpandedRowIds?: (expandedRowIds: string[]) => void;
  selectedItem?: any;
  handleClose?: () => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

const withCustomGridToolbar = (actions: (ToolbarAction | false | undefined)[]) => {
  return function WrappedGridToolbar(props: any) {
    const { options, context } = props;
    
    // Filter out false/undefined values and sort actions
    const sortedActions = actions
      .filter((action): action is ToolbarAction => !!action)
      .sort((a, b) => (a.order || 0) - (b.order || 0));

    return (
      <Grid container sx={{ width: '100%'}}>
        <GridToolbarContainer sx={{ fontWeight: 600 }}>
          {/* Render custom actions */}
          {sortedActions.map(action => (
            <React.Fragment key={action.key}>
              {React.cloneElement(action.component as React.ReactElement, {
                ...props,
                context: context
              })}
            </React.Fragment>
          ))}
          
          {/* Default toolbar items */}
          {options?.showQuickFilter && 
            <GridToolbarFilterButton 
              slotProps={{
                button: { sx: { fontWeight: 600 } }
              }} 
            />
          }
          
          {options?.showColumnsButton && 
            <GridToolbarColumnsButton 
              slotProps={{
                button: { sx: { fontWeight: 600 } }
              }}
            />
          }
          
          {options?.showPrintOptions && 
            <GridToolbarExport 
              slotProps={{
                button: { sx: { fontWeight: 600 } }
              }}
            />
          }
        </GridToolbarContainer>
      </Grid>
    );
  };
};

export default withCustomGridToolbar;