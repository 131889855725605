import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useParams } from 'react-router-dom';
import { useUploadDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../snackbar/snackbarSlice';
import CustomButton from '../button/CustomButton';

const ImportComponent = ({
    tagName, 
    step, 
    activeStep, 
    setCompleted, 
    jobType,
    getJobTypeComponent,
    getModeSelect
}:any) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const {selectedItem} = useAppSelector((state:any) => state.stepper);
    const [selectedJobType, setSelectedJobType] = useState<any>({});
    const {project_id, budget_id, proposal_id, contract_id} = useParams();
    const [uploadData, {isLoading}] = useUploadDataMutation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSelectedJobType(jobType);
    },[jobType]);
    
    const handleFileChange = (event:any) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const resetState = () => {
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';  
        }
    }

    const handleImport = async () => {
        try {
            if (selectedFile && selectedItem) {    
                const formData = new FormData();
                formData.append('file', selectedFile);
                const id = step?.idType === "project_id" ? project_id : (
                    budget_id || 
                    proposal_id || 
                    contract_id || 
                    (Array.isArray(selectedItem) ? selectedItem?.[0]?._id : selectedItem?._id)
                );

                const response = await uploadData({
                    tagName,
                    url: step?.importUrl(id, selectedJobType?._id),
                    body: formData,  
                }).unwrap();

                dispatch(showSnackbar({ message: response?.message || 'Operation successful', type: 'success' }));
                setCompleted({[activeStep]: true});
                resetState();
            }
        } catch (error:any) {
            resetState();
            dispatch(showSnackbar({ 
                message: `Error: ${JSON.stringify(error?.data) || 'Failed to create data'}`, 
                type: 'error' 
            }));
        }   
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item sx={{display: "flex", justifyContent: "flex-start"}} xs={12}>
                {getModeSelect &&  getModeSelect()}
                {getJobTypeComponent && getJobTypeComponent()}
            </Grid>
            <Grid 
                item 
                xs={12} 
                sx={{ 
                    height: "200px", 
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center" 
                }}
                >
                {isLoading && (
                    <CircularProgress variant='indeterminate' sx={{ width: '100%' }} />
                )}
                
                {!isLoading && (
                    selectedFile ? (
                    <Typography variant="body1" fontWeight="500">{selectedFile?.name}</Typography> // Display selected file name
                    ) : (
                    <Typography variant="body1" fontWeight="500">No file selected</Typography> // Display placeholder text
                    )
                )}
            </Grid>
      
            {/* File Input and Import Button */}
            <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CustomButton
                        sx={{ fontWeight: 600 }}
                        variant="contained"
                        size="small"
                        color="primary"
                        component="label"
                        aria-label="Select file"
                        onClick={undefined}
                    >
                    Select File
                    <input 
                        type="file" 
                        hidden 
                        onChange={handleFileChange} 
                        aria-label="File input"  
                        ref={fileInputRef}
                    />
                    </CustomButton>
                
                    <CustomButton 
                        sx={{ marginLeft: 1, fontWeight: 600 }} 
                        variant="outlined" 
                        size="small"
                        color="primary"
                        onClick={handleImport} 
                        disabled={!selectedFile}
                    >
                    Import
                    </CustomButton>
                </Box>
            </Grid>
        </Grid>
      
    );
};

export default ImportComponent;
