
// React app urls
export const URLS = {
    root: "/",
    login: "/login",
    logout: "/logout", 
    organizationalUnits: "/organizational-units",
    companies: "/companies",
    companyDetail: "/companies/:company_id",
    divisionDetail: "/companies/:company_id/divisions/:division_id",
    projects: "/projects",
    projectDetail: "/projects/:project_id",
    lotList: "/lots",
    fndSpecs: "/projects/:project_id/specifications/",
    checkList: "/projects/:project_id/project-checklist/",
    fndSpec: "/projects/:project_id/specifications/:specification",
    scheduleOfValues: "/projects/:project_id/sov",
    fndBudgets: "/fnd-budgets",
    fwSpecs: "/fw-specs",
    fwBudgets: "/fw-budgets",
    warehouseMaterialPricing: "/warehouse-materials",
    warehouseOrders: "/warehouse-orders",
    warehouseOrderDetail: "/projects/:project_id/warehouse-orders/:start_id",
    warehouseOrder: "/projects/:project_id/warehouse-orders/:order_id",
    plps: "/projects/:project_id/pre-built-orders",
    concreteMixDesignPricing: "/concrete/mix-design-pricings",
    aggregatePricing: "/concrete/aggregate-pricing",
    plp: "/projects/:project_id/pre-built-orders/:plp_id",
    starts: "/starts",
    startDetail: "/projects/:project_id/starts/:start_id",
    reports: "/projects/:project_id/office-reports/:start_id",
    fieldReports: "/projects/:project_id/field-reports/:start_id",
    contracts: "/contracts",
    contract: "/projects/:project_id/contracts/:contract_id",
    contractProposalReview: "/projects/:project_id/contract-proposal-review",
    proposals: "/projects/:project_id/proposals/",
    proposal: "/projects/:project_id/proposals/:proposal_id",
    budgets: "/projects/:project_id/budgets/",
    budget: "/projects/:project_id/budgets/:budget_id",
    assurance: "/projects/:project_id/assurance/:start_id",
    futureOrders: "/future-orders",
};

// API Endpoints
// Dropdowns        
const dropdown_urls = { 
    url: "/dropdowns",
    getCompanies: `/dropdowns/companies`,
    getDivisionNumbers: `/dropdowns/divisions`,
    getSupervisors: `/dropdowns/supervisors`,
    getProjects: (company_number, division_number) => `/dropdowns/projects?company_number=${company_number}&divsion_number=${division_number}`,
    getJobTypes: () => `/dropdowns/job-types`,
    getSovItems: (project_id, job_type) => `/dropdowns/projects/sov-items?project_id=${project_id}&job_type=${job_type}`,
    getPlans: (company_number, job_number) => `/dropdowns/starts/job-plans?company_number=${company_number}&job_number=${job_number}`,
    getOptions: (company_number, job_number, plan) => `/dropdowns/starts/plan-options?company_number=${company_number}&job_number=${job_number}&plan=${plan}`,
    getContractNumbers: (project_id, job_type) => `/dropdowns/projects/contracts?project_id=${project_id}&job_type=${job_type}`,
    getProposalRevisions: (project_id, job_type) => `/dropdowns/projects/proposals?project_id=${project_id}&job_type=${job_type}`,
    getBudgetRevisions: (project_id, job_type) => `/dropdowns/projects/budgets?project_id=${project_id}&job_type=${job_type}`
}

// Projects
const project_urls = { 
    url: '/projects',
    getDetail: (id) => `/projects/${id}`,
    getSovItems: (project_id, job_type) => `/sov/items?project_id=${project_id}&job_type=${job_type}`,
    create: "/projects",
    update: (id) => `/projects/${id}`,
    delete: (id) => `/projects/${id}`,
}

// Project Mappings
const projectMappings = {
    url: '/project-mapping',
    update: (id) => `/project-mapping/${id}`,
    delete: (id) => `/project-mapping/${id}`,
}

// Schedule of Values
const sov = { 
    url: (project_id, job_type_id) => `/sov?project_id=${project_id}`,
    getItems: (project_id, job_type_id) => `/sov?project_id=${project_id}`,
    create: (project_id) => `/sov?project_id=${project_id}`,
    update: (id) => `/sov?id=${id}`,
    delete: (id) => `/sov?id=${id}`
}

// Schedule of Value Items
const sovItems = { 
    url: (project_id, job_type_id) => `/sov?project_id=${project_id}&job_type_id=${job_type_id}`,
    update: (id) => `/sov?id=${id}`,
    delete: (id) => `/sov?id=${id}`
}

// Concrete Mix Design
const concrete_mix_design_urls = { 
    url: '/fnd-concrete-pricings',
    create: "/fnd-concrete-pricings",
    update: (id) => `/fnd-concrete-pricings/${id}`,
    delete: (id) => `/fnd-concrete-pricings/${id}`,
}

// Aggregate Pricing
const aggregate_Pricing_urls = { 
    url: '/fnd-aggregate-pricings',
    create: "/fnd-aggregate-pricings",
    update: (id) => `/fnd-aggregate-pricings/${id}`,
    delete: (id) => `/fnd-aggregate-pricings/${id}`,
}

// Project URLS

// Project Notes
const projectInformation = {
    url: "/project-information",
    create: "/project-information",
    update: (id) => `/project-information/${id}`,
    delete: (id) => `/project-information/${id}`,
};

// Plan Design
const planDesigns = {
    url: "/plan-designs",
    create: "/plan-designs",
    update: (id) => `/plan-designs/${id}`,
    delete: (id) => `/plan-designs/${id}`,
};

// Specifications
const specifications = {
    url: "/specifications",
    create: "/specifications",
    update: (id) => `/specifications/${id}`,
    delete: (id) => `/specifications/${id}`,
};

// Checklist
const checklist = {
    url: "/project-checklist",
    create: "/project-checklist",
    update: (id) => `/project-checklist/${id}`,
    delete: (id) => `/project-checklist/${id}`,
};

// Budgets
const budgets = { 
    url: "/fnd-budgets",
    getDetail: (id) => `/fnd-budgets?project_id=${id}`,
    getItems: (id) => `/fnd-budgets/items?id=${id}`,
    create: (id) => `/budget/items?id=${id}`,
    update: (id) => `/fnd-budgets?id=${id}`,
    delete: (id) => `/foundation-budgets/${id}`,
};

// Budget Items
const budgetItems = { 
    url: "/foundation-budget-items",
    getDetail: (id) => `/foundation-budget-items?project_id=${id}`,
    getItems: (id) => `/foundation-budget-items/items?id=${id}`,
    create: (id) => `/budget/items?id=${id}`,
    update: (id) => `/foundation-budget-items/${id}`,
    delete: (id) => `/foundation-budget-items/${id}`,
};

// Proposals
const proposals = { 
    url: "/proposals",
    getDetail: (id) => `/proposals?project_id=${id}`,
    getItems: (id) => `/proposals/items?proposal_id=${id}`,
    createItem: (id) => `/proposal/items?proposal_id=${id}`,
    manageItem: (id) => `/proposals/items?id=${id}`,
    update: (id) => `/proposals?id=${id}`,
    delete: (id) => `/proposals?id=${id}`,

}

// Proposal Items
const proposalItems = { 
    url: "/proposal-items",
    getDetail: (company_number, job_number) => `/proposals?company_number=${company_number}&job_number=${job_number}`,
    getItems: (id) => `/proposals/items?proposal_id=${id}`,
    update: (id) => `/proposals/items?id=${id}`,
    delete: (id) => `/proposals/items?id=${id}`
}

// Contracts
const contracts = { 
    url: "/contracts",
    getDetail: (id) => `/contracts?project_id=${id}`,
    getItems: (id) => `/contracts/items?contract_id=${id}`,
    create: (id) => `/contract/items?contract_id=${id}`,
    manageItem: (id) => `/contracts/items?id=${id}`,
    update: (id) => `/contracts?id=${id}`,
    delete: (id) => `/contracts?id=${id}`,

}

// Contract Items
const contractItems = { 
    url: "/contract-items",
    getItems: (id) => `/contracts/items?contract_id=${id}`,
    update: (id) => `/contracts/items?id=${id}`,
    delete: (id) => `/contracts/items?id=${id}`,

}

// Lots 
const lots = {
    url: "/lots",
    createItem: (project_id, start_id) => `/lots?project_id=${project_id}${start_id ? `&start_id=${start_id}`: ``}`,
    getItems: (id) => `/lots?project_id=${id}`,
    update: (id) => `/lots?id=${id}`,
    delete: (id) => `/lots?id=${id}`,
};

// Flatwork
const flatwork = {
    url: "/flatwork-sfs",
    createItem: "/flatwork-sfs",
    update: (id) => `/flatwork-sfs/${id}`,
    delete: (id) => `/flatwork-sfs/${id}`,
    getItems: (id) => `/flatwork-sfs/${id}`,
  
};

// Materials
const materials = {
    url: '/warehouse-materials',
    create: '/warehouse-materials',
    update: (id) => `/warehouse-materials/${id}`,
    delete: (id) => `/warehouse-materials/${id}`,
};

// Warehouse Orders
const warehouseOrders = {
    url: "/warehouse/orders",
    create: (start_id) => `/warehouse/orders?start_id=${start_id}`,
    // getDetail: (id) => `/starts/details?id=${id}`,
    // getItems: (params) => `/warehouse/orders?start_id=${params?.id}`,
    // createItem: `/warehouse/orders/items`,
    // manageItem: (id) => `/warehouse/orders/items?id=${id}`,
    update: (id) => `/warehouse/orders?id=${id}`,
    delete: (id) => `/warehouse/orders?id=${id}`,
};

// Warehouse Orders by Start ID
const warehouseOrdersByStartID = {
    url: '/warehouse/orders/starts',
    create: (start_id) => `/warehouse/orders?start_id=${start_id}`,
    update: (id) => `/warehouse/orders?id=${id}`,
    delete: (id) => `/warehouse/orders?id=${id}`,
};

// Warehouse Order Items
const warehouseOrderItems = {
    url: "/warehouse/orders/items",
    getItems: (params) => `/warehouse/orders?start_id=${params?.id}`,
    getDefaultItems: () => `/warehouse/orders/items/default`,
    create: (order_id) => `/warehouse/orders/items?order_id=${order_id}`,
    update: (id) => `/warehouse/orders/items?id=${id}`,
    delete: (id) => `/warehouse/orders/items?id=${id}`,
};

// Warehouse Order - Warehouse View
const warehouseOrdersDefault = {
    url: '/warehouse/orders/starts/default',
    create: (start_id) => `/warehouse/orders?start_id=${start_id}`,
    update: (id) => `/warehouse/orders?id=${id}`,
    delete: (id) => `/warehouse/orders?id=${id}`,
};

// Warehouse Order Items - Warehouse View
const warehouseOrderItemsDefault = {
    url: "/warehouse/orders/items",
    getItems: (params) => `/warehouse/orders?start_id=${params?.id}`,
    create: (order_id) => `/warehouse/orders/items?order_id=${order_id}`,
    update: (id) => `/warehouse/orders/items?id=${id}`,
    delete: (id) => `/warehouse/orders/items?id=${id}`,
};


// Warehouses
const warehouses = {
    url: "/warehouses",
    create: "/warehouses",
    update: (id) => `/warehouses/${id}`,
    delete: (id) => `/warehouses/${id}`,
};

// Prebuilt Orders
const prebuiltOrders = {
    url: '/prebuilt-orders',
    create: `/prebuilt-orders`,
    update: (id) => `/prebuilt-orders?id=${id}`,
    delete: (id) => `/prebuilt-orders?id=${id}`,
};

// Prebuilt Order Items
const prebuiltOrderItems = {
    url: '/prebuilt-orders',
    getDefaultItems: () => `/prebuilt-orders/items/default`,
    create: (order_id) => `/prebuilt-orders/items?prebuilt_order_id=${order_id}`,
    update: (id) => `/prebuilt-orders/items?id=${id}`,
    delete: (id) => `/prebuilt-orders/items?id=${id}`,
};

// Starts
const starts = {
    getDetail: (id) => `/starts/details?id=${id}`,
    url: (...args) => {
        const queryParams = new URLSearchParams(args).toString()
        return `/starts?${queryParams}`
    },
    create: (project_id) => `/starts?project_id=${project_id}`,
    update: (id) => `/starts?id=${id}`,
    delete: (id) => `/starts?id=${id}`,
};

// Starts Status
const startsStatus = {
    url: () => "/start-statuses",
    update: (id) =>  `/start-statuses/${id}`,
    delete: (id) =>  `/start-statuses/${id}`,
};

// Lots 
const lotsByStartID = {
    url: '/starts/items/lots',
    create: `/starts/items`,
    update: (id) => `/lots?id=${id}`,
    delete: (id) => `/starts/items?id=${id}`,
};

// Start Items
const startItems = {
    get: (id) => `/starts/items/lots?start_id=${id}`,
    create: `/starts/items`,
    update: (id) => `/starts/items?id=${id}`,
    delete: (id) => `/starts/items?id=${id}`,
};

// Reports
const report_urls = {
    url: "/reports"
};

// Companies
const companies = {
    url: () => "/companies",
    update: (id) =>  `/companies/${id}`,
    delete: (id) =>  `/companies/${id}`,
};

// Divisions
const divisions = {
    url: () => "/divisions",
    update: (id) =>  `/divisions/${id}`,
    delete: (id) =>  `/divisions/${id}`,
};

// Trades
const trades = {
    url: () => "/trades",
    update: (id) =>  `/trades/${id}`,
    delete: (id) =>  `/trades/${id}`,
};

// Job Types
const jobTypes = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/job-types?${params}`
    },
    update: (id) =>  `/job-types/${id}`,
    delete: (id) =>  `/job-types/${id}`,
};

// Customers
const customers = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/customers?${params}`
    },
    update: (id) =>  `/customers/${id}`,
    delete: (id) =>  `/customers/${id}`,
};

// Supervisors
const supervisors = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/supervisors?${params}`
    },
    update: (id) =>  `/supervisors/${id}`,
    delete: (id) =>  `/supervisors/${id}`,
};

// Estimators
const estimators = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/estimators?${params}`
    },
    update: (id) =>  `/estimators/${id}`,
    delete: (id) =>  `/estimators/${id}`,
};

// Foremen
const foremen = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/foremen?${params}`
    },
    update: (id) =>  `/foremen/${id}`,
    delete: (id) =>  `/foremen/${id}`,
};

// JC Accountants
const jcAccountant = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/jc-accountants?${params}`
    },
    update: (id) =>  `/jc-accountants/${id}`,
    delete: (id) =>  `/jc-accountants/${id}`,
    };

// Cost Code Segment One
const costCodesSegmentOne = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/cost-code-segment-one?${params}`
    },
    update: (id) =>  `/cost-code-segment-one/${id}`,
    delete: (id) =>  `/cost-code-segment-one/${id}`,
};

// Cost Code Segment Two
const costCodesSegmentTwo = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/cost-code-segment-two?${params}`
    },
    update: (id) =>  `/cost-code-segment-two/${id}`,
    delete: (id) =>  `/cost-code-segment-two/${id}`,
};

// Cost Types
const costTypes = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/cost-types?${params}`
    },
    update: (id) =>  `/cost-types/${id}`,
    delete: (id) =>  `/cost-types/${id}`,
};

// Cost Codes
const costCodes = {
    url: (args) => {
        const params = new URLSearchParams(args).toString();
      
        return `/cost-codes?${params}`
    },
    update: (id) =>  `/cost-codes/${id}`,
    delete: (id) =>  `/cost-codes/${id}`,
};

const field_reports = {
    url: "/reports/field",
    getSlabFooting: () => '/reports/field/slab-footing',
    getStartItems: () => '/reports/field/start-items', 
    getFieldSummary: () => '/reports/field/summary',
};

const assurance = {
    url: "/assurance",
    getStatuses: () => '/assurance/statuses',
    create: () => '/assurance',
    update: (id) => `/assurance/${id}`,
    delete: (id) => `/assurance/${id}`,
};

const future_orders = {
    url: "/future-orders",
    import: () => '/future-orders/import',
    create: () => '/future-orders',
    update: (id) => `/future-orders/${id}`,
    delete: (id) => `/future-orders/${id}`,
};

// Staging Orders
const stagingOrders = {
    url: "/staging-orders",
    create: () => '/staging-orders',
    update: (id) => `/staging-orders/${id}`,
    delete: (id) => `/staging-orders/${id}`,
};

export const apiRouteMap = new Map();
apiRouteMap.set("/dropdowns", dropdown_urls);
apiRouteMap.set("/project-information", projectInformation)
apiRouteMap.set("/projects", project_urls);
apiRouteMap.set("/project-mapping", projectMappings);
apiRouteMap.set("/budgets", budgets);
apiRouteMap.set("/budgets/items", budgetItems);
apiRouteMap.set("/prebuilt-orders", prebuiltOrders);
apiRouteMap.set("/prebuilt-orders/items", prebuiltOrderItems);
apiRouteMap.set("/lots", lots);
apiRouteMap.set("/plan-designs", planDesigns);
apiRouteMap.set("/specifications", specifications);
apiRouteMap.set("/project-checklist", checklist);
apiRouteMap.set("/flatwork-sfs", flatwork);
apiRouteMap.set("/concrete/mix-design-pricings", concrete_mix_design_urls);
apiRouteMap.set("/fnd-aggregate-pricings", aggregate_Pricing_urls);


// Warehouse Routes
apiRouteMap.set("/warehouse-materials", materials);
apiRouteMap.set("/warehouse/orders", warehouseOrders);
apiRouteMap.set("/warehouses", warehouses);
apiRouteMap.set('/warehouse/orders/starts', warehouseOrdersByStartID);
apiRouteMap.set('/warehouse/orders/starts/items', warehouseOrderItems);

apiRouteMap.set('/warehouse/orders/starts/default', warehouseOrdersDefault);
apiRouteMap.set('/warehouse/orders/starts/default/items', warehouseOrderItemsDefault);
// Starts Routes
apiRouteMap.set("/starts", starts);
apiRouteMap.set("/start-statuses", startsStatus);
apiRouteMap.set('/starts/items/lots', lotsByStartID);
apiRouteMap.set('/starts/items/lots/items', startItems);

apiRouteMap.set("/assurance", assurance);
apiRouteMap.set("/future-orders", future_orders);
apiRouteMap.set("/sov", sov);
apiRouteMap.set("/sov/items", sovItems);
apiRouteMap.set("/reports", report_urls);
apiRouteMap.set("/reports/field", field_reports);
apiRouteMap.set("/contracts", contracts);
apiRouteMap.set("/contracts/items", contractItems);
apiRouteMap.set("/proposals", proposals);
apiRouteMap.set("/proposals/items", proposalItems);
apiRouteMap.set("/companies", companies);
apiRouteMap.set("/divisions", divisions);
apiRouteMap.set("/trades", trades);
apiRouteMap.set("/job-types", jobTypes);
apiRouteMap.set("/customers", customers);
apiRouteMap.set("/supervisors", supervisors);
apiRouteMap.set("/estimators", estimators);
apiRouteMap.set("/foremen", foremen);
apiRouteMap.set("/jc-accountants", jcAccountant);
///cost-code-segment-one
apiRouteMap.set("/cost-code-segment-one", costCodesSegmentOne);
apiRouteMap.set("/cost-code-segment-two", costCodesSegmentTwo);
apiRouteMap.set("/cost-types", costTypes);
apiRouteMap.set("/cost-codes", costCodes);
apiRouteMap.set("/staging-orders", stagingOrders);