import { Grid } from '@mui/material';
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import FileUploadButton from './FileUploadButton';


const FileUploadToolbar = (props:any) => {
    const {documentTypes} = props?.context?.detail?.toolbarProps || []; 
    return(
        <GridToolbarContainer >
            <Grid container sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", fontWeight: 600}} spacing={1}>
                <Grid item>
                    <FileUploadButton {...props} documentTypes={documentTypes || []} />
                </Grid>
                <Grid item>
                    <GridToolbarFilterButton slotProps={{
                        button: {
                            sx: {fontWeight: 600}
                        }
                    }} />
                </Grid>
            </Grid>
        </GridToolbarContainer>
           
    )
}

export default FileUploadToolbar;
