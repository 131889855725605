import { GridColDef } from "@mui/x-data-grid-premium";
import metadataColumns from "../../components/columns/metadataColumns";
const segmentTwoColumns:GridColDef[] = [
    {
        field: 'segment_two',
        headerName: 'Segment Two',
        type: 'string',
        width: 80,
        filterable: true,
        editable: true,
    },
    {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        width: 220,
        filterable: true,
        editable: true,
    },
    ...metadataColumns
];

export default segmentTwoColumns;