import { IconButton, Link, Stack, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useDeleteDataMutation } from '../../api/apiSliceV2';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { WIDTHS } from '../../../utils/columnUtil';

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  lotNumber: string;
  startNumber: string;
}

const ConfirmationModal = ({ open, onClose, onConfirm, lotNumber, startNumber }: ConfirmationModalProps) => (
  <Dialog 
    open={open} 
    onClose={onClose} 
    maxWidth="xs" 
    fullWidth 
    sx={{
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',  
      },
      '& .MuiDialog-paper': {
        backgroundColor: "white",
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'  
      }
    }}
  >
    <DialogTitle>Confirm Removal</DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure you want to remove Lot {lotNumber} from Start {startNumber}?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="error" variant="contained">
        Remove
      </Button>
    </DialogActions>
  </Dialog>
);

const useLotColumns = (): GridColDef[] => {
  const location = useLocation();
  const {project_id} = useParams();
  const {selectedProject, activeTag} = useAppSelector((state:any) => state.api);
  const [deleteData] = useDeleteDataMutation();
  const [modalOpen, setModalOpen] = useState<{
    open: boolean;
    startId: string;
    lotId: string;
    lotNumber: string;
    startNumber: string;
  }>({
    open: false,
    startId: '',
    lotId: '',
    lotNumber: '',
    startNumber: ''
  });
  

  // remove lot from start
  const handleRemoveLot = async (start_id: string, lot_id: string) => {
    try {
      await deleteData({
        tagName: [activeTag, activeTag?.includes("/Lots") ? activeTag : `${activeTag}/lots`],
        url: `/starts/items/lots?start_id=${start_id}&lot_id=${lot_id}`
      }).unwrap();
      setModalOpen(prev => ({ ...prev, open: false }));
    } catch (error) {
      console.log("error deleting data");
    }
  };

  const handleConfirmRemove = () => {
    handleRemoveLot(modalOpen.startId, modalOpen.lotId);
  };

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'lot_number',
      headerName: 'Lot',
      width: WIDTHS.XS,
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      editable: true
    },
    {
      field: 'subjob_and_job_type',
      headerName: 'Start/Job Type',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      width: WIDTHS.XL,
      editable: false, 
      renderCell: (params: any) => {
        const startDetails = params?.row.startDetails || [];
        const subjobNumbers = startDetails
          .filter((start: any) => start?.subjob_number)
          .map((start: any) => {
            const linkPath = `/projects/${project_id}/starts/${start._id}`;
            return (
              <Stack key={start._id} direction="row" alignItems="center" spacing={0}>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <Link 
                    href={linkPath} 
                    style={{ 
                      color: "#0074D9", 
                      textDecoration: "underline", 
                      display: "flex", 
                      alignItems: "center", 
                      textWrap: "nowrap"
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {`${start.subjob_number} -`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {start?.job_type?.job_type_description || 'N/A'}
                    </Typography>
                  </Link>
                </Stack>
                <IconButton
                  aria-label="remove"
                  size="small"
                  onClick={() => setModalOpen({
                    open: true,
                    startId: start._id,
                    lotId: params.row._id,
                    lotNumber: params.row.lot_number,
                    startNumber: start.subjob_number
                  })}
                  sx={{marginBottom: 2}}
                >
                  <ClearSharpIcon fontSize='small' />
                </IconButton>
                <ConfirmationModal
                  open={modalOpen.open}
                  onClose={() => setModalOpen(prev => ({ ...prev, open: false }))}
                  onConfirm={handleConfirmRemove}
                  lotNumber={modalOpen.lotNumber}
                  startNumber={modalOpen.startNumber}
                />
              </Stack>
            );
          });

        return (
          <Stack direction="row">
            {subjobNumbers.length > 0 ? subjobNumbers?.map((subjob:any) => (
              <span key={subjob.key}>{subjob}</span>
            )) : <span>{'N/A'}</span>}
          </Stack>
        );
      },
    }, 
    {
      field: 'address',
      headerName: 'Address',
      sortable: true,
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: WIDTHS.MEDIUM,
      editable: true,
    },
    {
      field: 'garage_orientation',
      headerName: 'Orientation',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      width: WIDTHS.SMALL,
      editable: true,
      // renderEditCell: (value: any) => {
      //   return (
      //     <Select value={value} onChange={(e:any) => value.api.setEditCellValue({ id: value.id, field: value.field, value: e.target }, e)}>
      //       <MenuItem value="R">R</MenuItem>
      //       <MenuItem value="L">L</MenuItem>
      //     </Select>
      //   )
      
      // },
    }, 
    {
      field: 'customer_plan',
      headerName: 'Customer Plan',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      editable: true,
      width: WIDTHS.XS,
    }, 
    {
      field: 'customer_elevation',
      headerName: 'Customer Elevation',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      editable: true,
      width: WIDTHS.XS,
    }, 
    {
      field: 'customer_release',
      headerName: 'Customer Release',
      headerAlign: "left",
      align: "left",
      type: 'string',
      sortable: true,
      editable: true,
      width: WIDTHS.XS,
    },
    {
      field: 'finished_floor',
      headerName: 'FF',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: WIDTHS.XS,
    },
    {
      field: 'lot_pad_height',
      headerName: 'PAD',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: WIDTHS.XS,
    },
    {
      field: 'finished_grade',
      headerName: 'FG',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: WIDTHS.XS,
    },
    {
      field: 'garage_finished_floor',
      headerName: 'GFF',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: WIDTHS.XS,
    },
    {
      field: 'garage_lip',
      headerName: 'GL',
      headerAlign: "left",
      align: "left",
      type: 'number',
      editable: true,
      width: WIDTHS.XS,
    },    
    {
      field: 'assessors_parcel_number',
      headerName: 'APN',
      headerAlign: "left",
      align: "left",
      type: 'string',
      editable: true,
      width: WIDTHS.XS,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      headerAlign: "left",
      align: "left",
      type: 'date',
      width: WIDTHS.SMALL,
      filterable: true,
      editable: false,
      valueGetter: (value: any) => value ? new Date(value) : undefined,
      valueFormatter: (value: any) => {
        if (!value) {
          return '';
        }
        const date = new Date(value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: WIDTHS.SMALL,
      filterable: true,
      editable: false,
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      headerAlign: "left",
      align: "left",
      type: 'date',
      width: WIDTHS.SMALL,
      filterable: true,
      editable: false,
      valueGetter: (value: any) => value ? new Date(value) : undefined,
      valueFormatter: (value: any) => {
        if (!value) {
          return '';
        }
        const date = new Date(value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
    },
    {
      field: 'updated_by',
      headerName: 'Updated By',
      headerAlign: "left",
      align: "left",
      type: 'string',
      width: WIDTHS.SMALL,
      filterable: true,
      editable: false,
    }
  ], [location, selectedProject, activeTag, project_id, modalOpen]);

  return columns;
};

export default { useLotColumns };