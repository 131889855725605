import { useCallback, useEffect, useMemo, useState } from "react";
import Grid from '@mui/material/Grid';
import {
  DataGridPremium,
  GridActionsCellItem,
  GridCellParams,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-premium';
import { useAppDispatch } from "../../../app/hooks";
import CancelIcon from "@mui/icons-material/Close";
import styles from "./detail.module.css";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { apiRouteMap } from "../../routes/urls";
import { useUpdateDataMutation, useDeleteDataMutation, useCreateDataMutation } from "../../api/apiSliceV2";
import { showSnackbar } from "../snackbar/snackbarSlice";
import DocumentView from "../documents/DocumentView";
import ConfirmationDelete from "../dialog/ConfirmationDelete";

function PanelContent(props:any) {
    const {row, items, routeSegment, columns, context, tagName, editable} = props;
    const [rows, setRows] = useState<any>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const dispatch = useAppDispatch();
    const [updateData] = useUpdateDataMutation();
    const [deleteData] = useDeleteDataMutation();
    const [open, setOpen] = useState(false);
    const [activeRow, setActiveRow] = useState<any>(null);
    const [editMode] = useState<boolean>(false);
    const [createData] = useCreateDataMutation(); 
    useEffect(() => {
      if(items) setRows(items);
      return () => setRows([]);
    },[row, items]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };

    const handleConfirmDeletion = (id: GridRowId) => {
      const row = rows.find((row:any) => row._id === id);
      if(row) {
        setActiveRow(row);
        setOpen(true);
      }
    };
    
    const handleDeleteItem = useCallback(async (id: GridRowId) => {
      try {
        await deleteData({
          tagName,
          url: apiRouteMap.get(`${routeSegment}/items`).delete(id)
        }).unwrap();
        dispatch(showSnackbar({ message: `Item deleted: ${id}`, type: 'success' }));
        setRows((currentRows: any[]) => currentRows.filter((row:any) => row._id !== id));
      } catch (error:any) {
        dispatch(showSnackbar({ message: error?.message, type: 'error' }));
      }
    },[deleteData, dispatch, tagName, routeSegment]);
         
    const handleCancelClick = (id: GridRowId) => () => {
      setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row:any) => row._id === id);
      if (editedRow && editedRow!.isNew) {
          setRows(rows.filter((row:any) => row._id !== id));
      }
    };

    const handleAddItems = useCallback(async (items: any[]) => {
      try {
          const data = items.length > 1 ? items : [items];
          const formattedData = data.map((item: any) => {
            const {_id, ...newItem} = item;
            return {
              ...newItem,
              segment_one: item?.cost_code?.split(".")[0],
              segment_two: item?.cost_code?.split(".")[1],
              requested_quantity: 1,
              fulfilled_quantity: 1,
            }
          });
          const res = await createData({
              tagName,
              url: apiRouteMap.get(`${routeSegment}/items`).create(row?._id),
              body: {
                items: formattedData
              }
          }).unwrap();
          setRows([...rows, ...res?.data]);
      } catch (err: any) {
          console.log(err)
      }
    }, [createData, rows, routeSegment, tagName]);
    
    const processRowUpdate = useCallback(async (newRow: any) => {
      try {
        const id = newRow._id || newRow?.id || newRow?.order_id;
        const body = {...newRow, _id: id};
        await updateData({
          tagName,
          body,
          url: apiRouteMap.get(`${routeSegment}/items`).update(id)
        }).unwrap();
        setRows((rows:any) => rows.map((row:any) => (row._id === id) ? newRow : row));
        return newRow;
      } catch (error:any) {
        console.log(error);
      }
    }, []);
  
    const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
      setRowModesModel(rowModesModel);
    }

    const deleteColumn = useMemo(() => {
      return {
        field: 'actions',
        type: 'actions',
        headerName: 'Delete',
        width: 80,
        cellClassName: 'delete',
        getActions: (props:any) => {
        const {id} = props;
        if(rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit){
          return [
            <GridActionsCellItem
            icon={<CancelIcon color="primary" />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            showInMenu
          />
        ];   
      }
      return [
          <GridActionsCellItem
            icon={<DeleteForeverSharpIcon color="primary" />}
            label="Delete"
            className="textPrimary"
            onClick={() => handleConfirmDeletion(id)}
            color="primary"
            showInMenu
            disabled={!editable}
          />  
        ];
        },
      };
    }, [rowModesModel, handleCancelClick, handleDeleteItem]);

    const getProps = useCallback(() => {
      let props = {};
      switch(routeSegment){
        case '/warehouse/orders/starts':
        case '/warehouse/orders/starts/default':
          props = {
            toolbar: {
              ...context?.panel?.toolbarProps?.toolbar,  
              row: row,
              order: row,
              orderType: 'warehouse-order',
              tagName: tagName || '',
              handleAddMaterial: handleAddItems
            }
          };
          break;
        case '/starts/items/lots':
          props = {
            footer: {
              ...context?.panel?.footerProps?.footer,
              row: row || {},
              context: context || {},
              tagName: tagName || ''
            }
          };
          break;
        default:
          break;
      }
      return props;
    }, [row, routeSegment, context, tagName]);

    return (
      <Grid container justifyContent="center" alignItems="center" className={styles.detailPanel} spacing={2}>
        {context?.panel?.hasDocuments &&
        <Grid item sm={12} xs={12} >
          <DocumentView _id={row?._id} />
        </Grid>}
        <Grid item sm={12} xs={12} sx={{position: "sticky"}}>
          <DataGridPremium
            sx={{
              fontSize: 14, 
              fontWeight: 500, 
              border: "1px solid rgba(0,0,0,1)",
              height: "auto",
              maxHeight: "62vh",
              '& .MuiDataGrid-row:hover': {
                opacity: "0.5"
              },
              '& .MuiDataGrid-columnHeader': {
                whiteSpace: 'normal',
                lineHeight: '1.5',
                wordWrap: 'break-word',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                overflow: 'visible',
                whiteSpace: 'normal',
                lineHeight: '1.5',
                wordWrap: 'break-word',
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{field: 'requested_quantity', sort: 'desc'}]
              },
              // pinnedColumns: {
              //   left: ['part_number']
              // }
            }}
            key={row}
            autosizeOnMount={true}
            className={styles.gridStyle}
            columns={context?.panel?.actions?.deletable ? [deleteColumn, ...(columns || [])] : (columns || [])}
            rows={rows || []}
            editMode="row"
            getRowId={row => row._id || row?.id || row?.order_id}
            density="compact"
            rowHeight={52 }
            rowBufferPx={10}
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              return params.isEditable ? `${styles.editableChild}` : '';
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              ...(context?.panel?.toolbar && { toolbar: context?.panel?.toolbar, editMode: editMode }),  
              ...(context?.panel?.footer && { footer: context?.panel?.footer, editMode: editMode }),  
            }}
            slotProps={getProps()} 
            //hideFooter={!context?.panel?.footer}
          />
        </Grid>
        <ConfirmationDelete row={activeRow} open={open} setOpen={setOpen} handleDelete={handleDeleteItem} />
      </Grid>
    );
}

export default PanelContent;


  