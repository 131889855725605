import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation, useParams } from "react-router-dom";
import { 
  DataGridPremium, 
  GridActionsCellItem, 
  GridCellParams, 
  GridEventListener, 
  GridRowEditStopReasons, 
  GridRowId, 
  GridRowModes, 
  GridRowModesModel,
  GridColDef, 
  DataGridPremiumProps,
  GridFilterModel,
  GridRowSelectionModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GRID_CHECKBOX_SELECTION_COL_DEF
} from "@mui/x-data-grid-premium";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import CancelIcon from "@mui/icons-material/Close";
import PanelContent from "./Panel";
import { setActiveTag, setSelectedItem } from "../../projects/projectSlice";
import styles from './detail.module.css';
import DocumentView from "../documents/DocumentView";
import { useGetDataQuery, useUpdateDataMutation, useDeleteDataMutation } from "../../api/apiSliceV2";
import { showSnackbar } from "../snackbar/snackbarSlice";
import { apiRouteMap } from "../../routes/urls";
import ConfirmationDelete from "../dialog/ConfirmationDelete";
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import { createTag, parseTag, TagTypes } from "../../api/tagTypes";
import { TagType } from "../../api/tagTypes";
import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';
import useOrdProcEnt from "../hooks/useOrdProcEnt";

interface DetailViewProps {
  tabs: any;
} 
  
const DetailView: React.FC<DetailViewProps> = ({ tabs }) => {
  const { company, division } = useAppSelector((state: any) => state.auth);
  const apiRef = useGridApiRef();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const routeSegment = `/${location.pathname.split("/")[3]}`;
  const {project_id, start_id } = useParams();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [rows, setRows] = useState<any>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(undefined);
  const [filterTimeout, setFilterTimeout] = useState<any>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [context, setContext] = useState<any>({});
  const tabsRef = useRef<HTMLDivElement>(null);
  const [activeRow, setActiveRow] = useState<any>({});
  const [open, setOpen] = useState<any>(false);

  const projectTag = useMemo(() => createTag(
    company?._id ? { type: TagTypes.COMPANIES, id: company._id } : undefined,
    division?._id ? { type: TagTypes.DIVISIONS, id: division._id } : undefined,
    project_id ? { type: TagTypes.PROJECTS, id: project_id } : undefined,
  ), [company?._id, division?._id, project_id]);

  const parsedProjectTag = useMemo(() => parseTag(projectTag), [projectTag]);
  const { fetchOrdProcEnt } = useOrdProcEnt();

  // Generate a unique tag name for the stepper
  const tagName = useMemo(() => createTag(
    ...parsedProjectTag,
    context?.tagType ? { type: context.tagType as TagType, id: start_id || "" } : undefined,
  ), [parsedProjectTag, context?.tagType, start_id]);

  useEffect(() => {
    dispatch(setActiveTag(tagName));
  }, [tagName, dispatch]);

  const detailParams = useMemo(() => {
    if (start_id) {
      return {
        url: '/starts/details',
        params: { id: start_id },
        tagName: `${start_id}-detail`
      };
    }
    if (project_id) {
      return {
        url: `/projects/${project_id}`,
        //params: { project_id },
        tagName: `${project_id}-detail`
      };
    }
    return null;
  }, [start_id, project_id, tagName]);

  const { data: detail } = useGetDataQuery(detailParams, {
    skip: !detailParams || !context?.header
  });

  useEffect(() => {
    if(detail) dispatch(setSelectedItem(detail));
  },[detail, dispatch]);

  const queryParams = useMemo(() => ({ 
    url: context?.route, 
    params: {
      ...context?.params,
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      filterModel: JSON.stringify(filterModel)
    }, 
    
    tagName
  }), [context, start_id, project_id, company, division, paginationModel, filterModel]);

  const {data: response,  isLoading} = useGetDataQuery(queryParams, {
    skip: !context?.route || !tagName
  });

  const [updateData] = useUpdateDataMutation();
  const [deleteData] = useDeleteDataMutation();

  useEffect(() => {
    if(response?.data) setRows(response?.data);
  },[response?.data, dispatch]);

  const handleTabChange = useCallback((_event: any, newValue: number) => {
    setCurrentTabIndex(newValue);
  }, []);

  // Optimize context setting
  useEffect(() => {
    const c: any = tabs?.find((c: any) => c?.index === currentTabIndex);
    setContext(c);
    //setRows([]);
  }, [currentTabIndex, tabs]);

  const onFilterChange = useCallback((newFilterModel: GridFilterModel) => {
    //Debounce filter change
    clearTimeout(filterTimeout);
    const newTimeout = setTimeout(() => {
      setFilterModel(newFilterModel);
    }, 150);
    setFilterTimeout(newTimeout);
  }, [filterModel]);

  const handleRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {  
    setRowSelectionModel(newRowSelectionModel);
  };

  const handleDetailPanelExpandedRowIdsChange = (newExpandedRowIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newExpandedRowIds.length > 1 ? [newExpandedRowIds[newExpandedRowIds.length - 1]] : newExpandedRowIds);
  };

  const getDetailPanelHeight = React.useCallback<NonNullable<DataGridPremiumProps['getDetailPanelHeight']>>(() => 'auto' as const, []);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleConfirmDeletion = useCallback((id: GridRowId) => {
    const row = response?.data?.find((r:any) => r?._id === id || r?.item_id === id || r?.id === id);
    setActiveRow(row);
    setOpen(true);
  }, [response?.data]);

  const getPath = (context: any) => {
    if (context?.label === 'Warehouse Orders') {
        return '/warehouse/orders';
    }
    return context?.route
  };

  const path = getPath(context);

  const handleDelete = useCallback(async (id: GridRowId) => {
    const url = apiRouteMap.get(path).delete(id, start_id);
    await deleteData({ tagName, url });
  }, [deleteData, context?.route, start_id, tagName]);

  const handleDeleteAll = useCallback(async () => {
    try {
      const ids = response?.data?.map((row: any) => row?._id || row?.id);
      await deleteData({
        ids,
        url: apiRouteMap.get(path).url,
        tagName
      });
      dispatch(showSnackbar({ message: `Documents deleted`, type: 'success' }));
    } catch (error) {
      console.error("Error deleting data: ", error);
    }
  }, [response?.data, deleteData, context?.route, tagName, dispatch]);

  const handleCancelClick = useCallback((id: GridRowId) => () => {
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    setRows((prevRows:any) => prevRows.filter((row: any) => !(row._id === id && row.isNew)));
  }, []);

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    try {
      const sanitizedRow = Object.keys(newRow).reduce((acc: any, key: string) => {
        const value = newRow[key];
  
        if (value && typeof value === 'object' && value._id) {
          acc[key] = value._id;
        } else {
          acc[key] = value;
        }
  
        return acc;
      }, {});
  
      const id = sanitizedRow._id || sanitizedRow.objectid;
      const response = await updateData({
        tagName, 
        body: sanitizedRow,   
        url: apiRouteMap.get(path).update(id)   
      }).unwrap();
  
      dispatch(showSnackbar({ message: response?.message || 'Update successful', type: 'success' }));
  
      return sanitizedRow;  // Return the sanitized row for the grid
  
    } catch (error: any) {
      dispatch(showSnackbar({ message: error?.data?.message || error?.message || 'Update failed', type: 'error' }));
      return oldRow;  // Revert to the old row in case of failure
    }
  }, [updateData, context, apiRouteMap, dispatch]);
  
  const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  }, []);

  // Add state for columns
  const [currentColumns, setCurrentColumns] = useState<GridColDef[]>([]);

  // Update columns when context changes
  useEffect(() => {
    if (!context?.detail?.columns) {
      setCurrentColumns([]);
      return;
    }

    const {expandable, selectable, ...rest} = context?.detail?.actions || {};
    const allActionsFalse = context?.detail?.actions && 
      Object.values(rest).every((value: any) => value === false);

    if (allActionsFalse) {
      setCurrentColumns(context.detail.columns);
      return;
    }

    const actionsColumn: GridColDef = {
      field: 'actions',
      type: 'actions',
      width: 40,
      headerName: 'Actions',
      getActions: (params: any) => {
        const { id } = params;
        const actions = [];

        if (rowModesModel[id]?.mode === GridRowModes.Edit) {
          actions.push(
            <GridActionsCellItem
              key="cancel"
              icon={<CancelIcon color="primary" />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="primary"
              showInMenu
            />
          );
        } else if (!allActionsFalse) {
          Object.entries(context.detail.actions || {}).forEach(([key, value]) => {
            if (key === 'exportable' && value) {
              actions.push(
                <GridActionsCellItem
                  key="export"
                  icon={<GetAppSharpIcon color="primary" />}
                  label="Export"
                  className="textPrimary"
                  onClick={() => fetchOrdProcEnt([id], params.row.order_number)}
                  color="primary"
                  showInMenu
                />
              );
            } else if (key === 'deletable' && value) {
              actions.push(
                <GridActionsCellItem
                  key="delete"
                  icon={<DeleteForeverSharpIcon color="primary" />}
                  label="Delete"
                  className="textPrimary"
                  onClick={() => handleConfirmDeletion(id)}
                  color="primary"
                  showInMenu
                />
              );
            }
          });
        }

        return actions;
      },
    };

    setCurrentColumns([actionsColumn, ...(context.detail.columns || [])]);
  }, [context, rowModesModel, handleCancelClick, handleConfirmDeletion, fetchOrdProcEnt]);

  const getDetailPanelContent = useCallback(({ row }: any) => {
    if (!context?.detail?.actions?.expandable) return null;
    const rowData = response?.data?.find((r: any) => r._id === row._id);
    const items = rowData?.startItems || rowData?.order_items || rowData?.items || [];
    const lockEdit = context?.route === '/warehouse/orders/starts' && rowData?.order_status === 'FULFILLED';
    const panelColumns = lockEdit ? context?.panel?.columns?.map((col:any) => ({...col, editable: false})) : context?.panel?.columns;
    return (
      context?.panel?.component ?
      (
      <context.panel.component
        editable={!lockEdit}
        tagName={tagName}
        context={context}
        row={rowData}
        routeSegment={context?.route}
        columns={panelColumns || []}
        items={items}
        currentTabIndex={currentTabIndex}
      />
    ):(
      <PanelContent
        editable={!lockEdit}
        tagName={tagName}
        context={context}
        row={rowData}
        routeSegment={context?.route}
        columns={panelColumns || []}
        items={items}
        currentTabIndex={currentTabIndex}
      />
      )
    );
  }, [context, response?.data, currentTabIndex, tagName]);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...context?.detail?.initialState,
      rowGrouping: { model: ['idType'] },
      columns: {
        columnVisibilityModel: {
          subjob_and_job_type: false,
          schedule_of_values_id: false,
        }
      },
      pinnedColumns: {
        left: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field, 
          'job_number', 
          'subjob_number', 
          'company_number', 
          'division_number', 
        ]
      },
      sorting: {
        sortModel: [{
          field: 'created_at', sort: 'desc'
        },
        {
          field: 'order_number', sort: 'asc'
        }
        ]
      }
    },
  });

  return (
    <Grid container justifyContent="center" alignItems="flex-end" className={styles.outer}>
      {context?.header &&
        <context.header editable={false} context={context} />
      }

      {/* Tabs */}
      <Grid item xs={12} justifyContent="start" sx={{height: "auto"}}>
        <Tabs
          ref={tabsRef}
          value={currentTabIndex}
          defaultValue={0}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {tabs?.map((tab: any, index: number) => (
            <Tab key={index} label={tab?.label || "Default Label"} value={tab.index} sx={{fontWeight: 700}} />
          ))}
        </Tabs>
      </Grid>

      {/* Conditional Content */}
      {context?.detail?.hasDocuments ? (
        <Grid item justifyContent="center" alignItems="flex-start" sx={{ height: "800px", width: "100%" }}>
          <DocumentView context={context} routeSegment={routeSegment} tagName={tagName} />
        </Grid>
      ) : (
        <Grid item sm={12} xs={12} sx={{ flexGrow: 1, height: '100%', width: '100%'}}>
          <DataGridPremium
            sx={{
              fontSize: 14,
              opacity: 0.9,
              maxHeight: '70vh',
              height: 'auto',
              width: '100%',
              flexGrow: 1,
              py: 1,
              border: "1px solid rgba(255,255,255,0.5)",
              '& .MuiDataGrid-row:hover': {
                opacity: "0.5",
              },
              '& .MuiDataGrid-columnHeader': {
                whiteSpace: 'normal',
                lineHeight: '1.5',
                wordWrap: 'normal',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: "bold",
                whiteSpace: 'normal',
                lineHeight: '1',
                wordWrap: 'normal',
              },
            }}
            key={tagName}
            apiRef={apiRef}
            initialState={initialState}
            checkboxSelection={context?.detail?.actions?.selectable || false}
            editMode="cell"
            getRowId={(row: any) => row?._id || row?.id || row?.order_id}
            density="compact"
            rows={response?.data || []}
            columns={currentColumns || []}
            loading={isLoading}
            rowHeight={52}
            rowModesModel={
              rowModesModel}
            defaultGroupingExpansionDepth={-1}
            pagination
            paginationMode="server"
            rowCount={response?.total || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
            pageSizeOptions={[100, 250, 500]}
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={onFilterChange}
            onRowModesModelChange={handleRowModesModelChange}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error: any) => console.log(error)}
            slots={{
              toolbar: context?.detail?.toolbar,
              ...(context?.detail?.actions?.expandable ? {
                detailPanelExpandIcon: KeyboardArrowDownSharpIcon,
                detailPanelCollapseIcon: KeyboardArrowUpSharpIcon,
              } : {})
            }}
            slotProps={{ toolbar: { 
              
              row: activeRow,
              columns: currentColumns,
              context,
              tagName,
              selectedRows: rows?.filter((row: any) => rowSelectionModel.includes(row._id)),
              isUpdateMode: true,
              ...(context?.detail?.actions?.deletable ? { handleDeleteAll } : {}),
              options: context?.detail?.toolbarProps?.options || {
                showHandleDeleteAll: context?.detail?.actions?.deletable,
                showQuickFilter: true,
                showPrintOptions: true,
                showExpandAll: context?.detail?.actions?.expandable,
              },
              expandedRowIds: detailPanelExpandedRowIds,
              rows: response?.data || [],
              setExpandedRowIds: setDetailPanelExpandedRowIds,
              },
              ...context?.detail?.toolbarProps,
            }}
            {...(context?.detail?.actions?.expandable ? {
              getDetailPanelHeight: getDetailPanelHeight,
              getDetailPanelContent: getDetailPanelContent,
              detailPanelExpandedRowIds: detailPanelExpandedRowIds,
              onDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange
            } : {})}
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              return params.isEditable ? `${styles.editableCell}` : '';
            }}
            getRowClassName={(params) => {
              if (rowSelectionModel.includes(params.id)) {
                return styles.selected;
              }
              if (detailPanelExpandedRowIds.includes(params.id)) {
                return styles.highlighted;
              }
              return '';
            }}
          />
        </Grid>
      )}
      <ConfirmationDelete row={activeRow} open={open} setOpen={setOpen} handleDelete={handleDelete} />

    </Grid>
  );
}

export default DetailView;