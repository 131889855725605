import { parseISO, isValid, format } from "date-fns";
import { toZonedTime } from "date-fns-tz";

const timeZone = 'America/Los_Angeles'; // PST timezone

export const formatDateString = (dateString: string) => {
  try {
    const date = parseISO(dateString);
    if (isValid(date)) {
      const zonedDate = toZonedTime(date, timeZone);
      return format(zonedDate, 'MMM d, yyyy');
    }
    return '';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Error';
  }
};

export const formatDateStringWithTime = (dateString: string) => {
  try {
    const date = parseISO(dateString);
    if (isValid(date)) {
      const zonedDate = toZonedTime(date, timeZone);
      return format(zonedDate, 'PPpp'); // Example format: 'Apr 29, 2020, 5:30 PM'
    }
    return "";
  } catch (error) {
    console.error('Error formatting date:', error);
    return "Error";
  }
};