
export const WIDTHS = {
    XS: 80,      // Very narrow columns (icons, small numbers)
    SMALL: 120,   // Short text, dates, status
    MEDIUM: 180,  // Normal text fields
    LARGE: 240,   // Longer text fields
    XL: 300,     // Complex content or multiple elements
    XXL: 400     // Very wide content
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});