import { useMemo } from "react";
import { TagTypes } from "../api/tagTypes";
import actions from "./actions/actions";
import CustomGridToolbar from "../components/grid/toolbars/CustomGridToolbar";
import { DetailConfig } from "../components/interfaces/DetailConfig";
import useCompanyColumns from "./columns/companyColumns";


const useCompanyConfig = () => {
    const companyCols = useCompanyColumns();
    const tabs:DetailConfig[] = useMemo(() => {
        const cols:DetailConfig[] = [  
            {
                route: "/companies",
                tagType: TagTypes.COMPANIES,
                index: 0,
                label: 'Companies',
                steps: actions?.createCompany,
                header: undefined,
                detail: {
                    initialState: {
                        sorting: {
                            sortModel: [{field: 'name', sort: 'asc'}]
                        },
                        pinnedColumns: {
                            left: ['company_number']
                        }
                    },
                    columns: companyCols,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {
                        options: {
                            showQuickFilter: true,
                            showPrintOptions: true,
                            showHandleDeleteAll: false,
                        }
                    },
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                params: undefined
            },
        ];
        return cols;    

    }, [companyCols]);

    return {
        tabs,
    };
};

export default useCompanyConfig;