import { GridColDef } from "@mui/x-data-grid-premium";
import metadataColumns from "../../components/columns/metadataColumns";
const foremenColumns:GridColDef[] = [
    {
      field: 'employee_number',
      headerName: 'Employee Number',
      type: 'string',
      width: 150,
      editable: true, 
    },
    {
      field: 'employee_name',
      headerName: 'Employee Name',
      type: 'string',
      width: 200,
      editable: true, 
    },
    {
      field: 'phone_number',
      headerName: 'Phone Number',
      type: 'string',
      width: 150,
      editable: true,
    },
    {
      field: 'crew_size',
      headerName: 'Crew Size',
      type: 'number',
      width: 150,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      width: 150,
      editable: true,
      valueOptions: ['ACTIVE', 'INACTIVE', 'ARCHIVED'],  // Options for the status field
      renderCell: (params:any) => (
        <span
          style={{
            color: params.value === 'ACTIVE' ? 'green' : params.value === 'INACTIVE' ? 'orange' : 'red',
          }}
        >
          {params.value}
        </span>
      ),
    },
    ...metadataColumns
  ];

  export default foremenColumns;