import CustomButton from "../../components/button/CustomButton";
import { useUpdateDataMutation } from "../../api/apiSliceV2";
import ConfirmAction from "../../components/modals/ConfirmAction";
import { useState } from "react";

const RejectOrder = (props:any) => {
    const {row, tagName} = props;
    const [updateData] = useUpdateDataMutation();
    const [open, setOpen] = useState<boolean>(false);
    const handleStatusUpdate = async () => {
        try {
            const requestData = {
                ...row,
                order_status: "REJECTED"
            };
            await updateData({
                tagName,
                url: `/warehouse/orders?id=${row?._id}`,
                body: requestData,
            }).unwrap();
            setOpen(false);
        } catch (error:any) {
            console.log(error)
        }
    }
    return (
        <>
            <CustomButton disabled={row?.order_status === 'REJECTED' || row?.order_status === 'FULFILLED'} size="small" color="error" variant="contained" sx={{color: "white"}} onClick={() => setOpen(true)}>Reject Order</CustomButton>
            <ConfirmAction title="Reject Order" message="Are you sure you want to reject this order?" onConfirm={handleStatusUpdate} onCancel={() => setOpen(false)} open={open} />
        </>
    )
}

export default RejectOrder;