import { TagTypes } from "../../api/tagTypes";
import lotColumns from "../../projects/columns/lotColumns";
import warehouseMaterialColumns from "../columns/warehouseMaterialColumns";
import CreateWarehouseOrder from "./CreateWarehouseOrder";
import SelectForm from "../../components/forms/SelectForm";
import warehouseColumns from "../columns/warehouseColumns";

//Create Warehouse order
const createWarehouseOrder = [
    {
        route: "/warehouse-orders",
        label: "Create Order",
        Component: CreateWarehouseOrder, 
        actionType: "submit",
        orderType: "warehouse order",
        tagType: TagTypes.DATA,
        idType: "start_id",
        saveUrl: (id:string) => `/warehouse/orders?start_id=${id}`,
        //importUrl: (id:string, job_type:any) => `/prebuilt-orders/items/import?project_id=${id}&job_type=${job_type?._id}`,
        getColumns: () => lotColumns?.useLotColumns(),
        isOptional: false
    },
];

//Fulfill warehouse order 
const fulfillWarehouseOrder = [
    {
        route: "/warehouse-orders",
        label: "Fulfill Order",
        Component: CreateWarehouseOrder, 
        actionType: "submit",
        tagType: TagTypes.DATA,
        idType: "start_id",
        saveUrl: (id:string) => `/warehouse/orders?id=${id}`,
        //importUrl: (id:string, job_type:any) => `/prebuilt-orders/items/import?project_id=${id}&job_type=${job_type?._id}`,
        getColumns: () => lotColumns?.useLotColumns(),
        isOptional: false
    },
];

const addWarehouseMaterial = [
    {
        route: "/warehouse-materials",
        label: "Add Material",
        Component: SelectForm,
        saveUrl: () => "/warehouse-materials",
        getColumns: () => warehouseMaterialColumns,
        importUrl: undefined,
        actionType: "submit",
        tagType: TagTypes.DATA,
    }
];

const createWarehouse = [
    {
        route: "/warehouses",
        label: "Add Warehouse",
        Component: SelectForm,
        saveUrl: () => "/warehouses",
        getColumns: () => warehouseColumns,
        importUrl: undefined,
        actionType: "submit",
        tagType: TagTypes.DATA,
    }
];

const warehouseSteps = {
    createWarehouseOrder,
    fulfillWarehouseOrder,
    addWarehouseMaterial,
    createWarehouse,
};

export default warehouseSteps;