import { Grid } from '@mui/material';
import { useMemo } from 'react';
import WarehouseSearchModal from './WarehouseSearchModal';
import { useLocation } from 'react-router-dom';
import FulFillOrder from '../../actions/FulFillOrder';
import RejectOrder from '../../actions/RejectOrder';
import RequestOrder from '../../actions/RequestOrder';
import withCustomGridToolbar from '../../../components/grid/toolbars/withCustomGridToolbar';

interface WarehouseSearchToolbarProps {
    row: any;
    type: string;
    tagName: string;
    action: boolean;
}

{/* <Box sx={{display: "flex", justifyContent: "flex-start", height: "auto", margin: 1}}>
            <GridToolbarContainer>
                {props?.action && 
                    <WarehouseSearchModal {...props} />
                }
                {props?.action && route !== "projects" && (
                    <>
                        {route === "warehouse-orders" ? (
                            <>
                                <FulFillOrder {...props} />
                                <RejectOrder {...props} />
                            </>
                        ) : (
                            <>
                                <RequestOrder {...props} />
                            </>
                        )}
                    </>
                )}
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        </Box> */}

const WarehouseSearchToolbar = (props: WarehouseSearchToolbarProps) => {
    const route = useLocation().pathname.split('/')[3];
    // Define toolbar actions
    const toolbarActions = useMemo(() => [

        props?.action && 
        {
            key: 'warehouseSearchModal',
            component: <WarehouseSearchModal {...props} />,
            order: 0
        },
        
        props?.action && route === "warehouse-orders" && 
        {
            key: 'fulfillOrder',
            component: <FulFillOrder {...props} />,
            order: 1
        },

        props?.action && route === "warehouse-orders" && 
        {
            key: 'rejectOrder',
            component: <RejectOrder {...props} />,
            order: 2
        },

        props?.action && route !== "warehouse-orders" && 
        {
            key: 'requestOrder',
            component: <RequestOrder {...props} />,
            order: 3
        },
    ], [props]);
        
    // Create enhanced toolbar with actions
    const EnhancedToolbar = useMemo(() => 
        withCustomGridToolbar(toolbarActions), 
    [toolbarActions]);

    return (
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-start', padding: 1}}>
            <EnhancedToolbar {...props} />
        </Grid>
    );
};

export default WarehouseSearchToolbar;
