import { GridColDef } from '@mui/x-data-grid-premium';
import InventorySharpIcon from '@mui/icons-material/InventorySharp';
import DeliveryMethodEditComponent from '../../components/columns/edit-components/DeliveryMethodEditComponent';
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import PriorityHighSharpIcon from '@mui/icons-material/PriorityHighSharp';
import { Grid, Link, Typography } from '@mui/material';
import PendingActionsSharpIcon from '@mui/icons-material/PendingActionsSharp';
import AlarmAddSharpIcon from '@mui/icons-material/AlarmAddSharp';
import metadataColumns from '../../components/columns/metadataColumns';
import { WIDTHS } from '../../../utils/columnUtil';

const getOrderColumns = (includeMetadata: boolean): GridColDef[] => {
  const baseColumns: GridColDef[] = [
    {
      field: 'order_number',
      headerName: 'Order No.',
      width: WIDTHS.XS,
      type: 'string',
      sortable: true,
      headerAlign: 'left',
      align: 'left',
      editable: false
    },
    {
      field: 'prebuilt_order_number',
      headerName: 'Prebuilt Order No.',
      width: WIDTHS.SMALL,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: false
    },
    {
      field: 'lots', 
      headerName: 'Lots',
      width: WIDTHS.SMALL,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: false,
      valueGetter: (_value: any, row: any) => {
        const lots = row?.lots?.map((lot: any) => lot.lot_number).join(', ');
        return lots;
      },
    },
    {
      field: 'delivery_method',
      headerName: 'Delivery Method',
      width: WIDTHS.SMALL,
      type: 'string',
      sortable: true,
      editable: true,
      headerAlign: 'left',
      align: 'left',
      renderEditCell: (value: any) => <DeliveryMethodEditComponent {...value} />,
    },
    {
      field: 'order_status',
      headerName: 'Status',
      width: WIDTHS.SMALL,
      type: 'string',
      sortable: true,
      editable: false,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        let icon;
        let color;
        let text;

        switch (params.value) {
          case 'REQUESTED':
            icon = <PendingActionsSharpIcon />;
            color = "#0055A5";
            text = 'REQUESTED';
            break;
          case 'FULFILLED':
            icon = <TaskAltSharpIcon />;
            color = 'green';
            text = 'FULFILLED';
            break;
          case 'CREATED':
            icon = <AlarmAddSharpIcon />;
            color = 'green';
            text = 'CREATED';
            break;
          case 'REJECTED':
            icon = <PriorityHighSharpIcon />;
            color = 'red';
            text = 'REJECTED';
            break;
          case 'STAGED':
            icon = <InventorySharpIcon />;
            color = 'green';
            text = 'STAGED';
            break;
          default:
            text = params.value;
        }

        return (
          <Grid item sx={{ height: "100%", width: "100%", display: 'flex', alignItems: 'center', color, fontWeight: 600 }}>
            {icon && <span style={{ marginTop: 12, padding: 1 }}>{icon}</span>}
            <Typography fontWeight={600} variant="body2">{text}</Typography>
          </Grid>
        );
      },
    },
    {
      field: 'requested_date',
      headerName: 'Requested Date',
      width: WIDTHS.SMALL,
      type: 'date',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: true,
      valueFormatter: (value: any) => {
        if (!value) {
          return '';
        }
        const date = new Date(value);
        return date?.toLocaleDateString();
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: WIDTHS.LARGE,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: true
    },
    {
      field: 'ord_proc_ent_exported_at',
      headerName: 'Exported At',
      width: WIDTHS.SMALL,
      type: 'date',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: false,
      valueFormatter: (value: any) => {
        if (!value) {
          return '';
        }
        const date = new Date(value);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
    },
    {
      field: 'ord_proc_ent_exported_by',
      headerName: 'Exported By',
      width: WIDTHS.MEDIUM,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: false
    }
  ];

  return includeMetadata ? [...baseColumns, ...metadataColumns] : baseColumns;
};

const OrderColumns = getOrderColumns(true); // Include metadata columns
const OrderTrackerColumns: GridColDef[] = [
  {
    field: 'job_number',
    headerName: 'Job No.',
    width: WIDTHS.XS,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    sortable: true,
    editable: false,
  },
  {
    field: 'subjob_number',
    headerName: 'Subjob No.',
    width: WIDTHS.XS,
    type: 'string',
    headerAlign: "left",
    align: "left",
    sortable: true,
    editable: false,
    renderCell: (params: any) => {
      return (
        <Grid item sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Link href={`/projects/${params.row.project_id}/warehouse-orders/${params.row.start_id}`}>
            <Typography variant="body2" fontWeight={600} color="inherit" noWrap>
              {params.row.subjob_number}
            </Typography>
          </Link>
        </Grid>
      );
    },
  },
  ...getOrderColumns(false) // Exclude metadata columns
];

const WarehouseOrderFulfillColumns: GridColDef[] = [
    ...OrderColumns?.slice(0, 2),
    {
      field: 'ecms_order_number',
      headerName: 'ECMS Order Number',
      width: WIDTHS.SMALL,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: true
  },
  ...OrderColumns?.slice(2)
];

const WarehouseOrderRequestItemColumns:GridColDef[] =[ 
    {
      field: 'part_number',
      headerName: 'Part No.',
      width: WIDTHS.SMALL,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: false
    },
    {
      field: 'part_description',
      headerName: 'Description',
      width: WIDTHS.LARGE,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: false
    },

    {
      field: 'prebuilt_order_quantity',
      headerName: 'Prebuilt Qty',
      width: WIDTHS.XS,  
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: false
    },
    {
      field: 'requested_quantity',
      headerName: 'Requested Qty',
      width: WIDTHS.XS,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: true
    },
    {
      field: 'unit_of_measure',
      headerName: 'UoM',
      width: WIDTHS.XS,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: true
    },
    {
      field: 'segment_one',
      headerName: 'Seg One',
      width: WIDTHS.XS,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: true
    },
    {
      field: 'segment_two',
      headerName: 'Seg Two',
      width: WIDTHS.XS,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: true
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: WIDTHS.LARGE,
      type: 'string',
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      editable: true
    },
    ...metadataColumns
];

const WarehouseOrderFulfillItemColumns: GridColDef[] = [
  // Spread only the columns before 'requested_quantity'
  ...WarehouseOrderRequestItemColumns.slice(
    0, 
    WarehouseOrderRequestItemColumns.findIndex(col => col.field === 'requested_quantity') + 1
  ).map(col => 
    col.field === 'requested_quantity' ? { ...col, editable: false } : col
  ),
  // Add fulfilled_quantity
  {
    field: 'fulfilled_quantity',
    headerName: 'Fulfilled Qty',
    width: WIDTHS.SMALL,  
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    sortable: true,
    editable: true
  },
  // Spread the remaining columns
  ...WarehouseOrderRequestItemColumns.slice(
    WarehouseOrderRequestItemColumns.findIndex(col => col.field === 'requested_quantity') + 1
  )
];

export default {
  OrderColumns,
  OrderTrackerColumns,
  WarehouseOrderFulfillColumns, 
  WarehouseOrderRequestItemColumns, 
  WarehouseOrderFulfillItemColumns
}