import { GridColDef } from "@mui/x-data-grid-premium";
import metadataColumns from "../../components/columns/metadataColumns";
const customerColumns:GridColDef[] = [
    {
        field: 'customer_number',
        headerName: 'Customer No.',
        type: 'number',
        width: 80,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_description',
        headerName: 'Description',
        type: 'string',
        width: 220,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_legal_name',
        headerName: 'Legal Name',
        type: 'string',
        width: 180,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_principal',
        headerName: 'Principal',
        type: 'string',
        width: 180,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_state',
        headerName: 'State',
        type: 'string',
        width: 150,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_country',
        headerName: 'Country',
        type: 'string',
        width: 150,
        filterable: true,
        editable: true,
    },
    {
        field: 'customer_status',
        headerName: 'Status',
        type: 'string',
        width: 150,
        filterable: true,
        editable: true,
        // Optional: Custom rendering or color based on status
        renderCell: (params:any) => (
            <span style={{ color: params.value === 'ACTIVE' ? 'green' : params.value === 'INACTIVE' ? 'orange' : 'red' }}>
                {params.value}
            </span>
        ),
    },
    ...metadataColumns
];

export default customerColumns;