import metadataColumns from '../../components/columns/metadataColumns';
import { GridColDef } from '@mui/x-data-grid-premium';

export const warehouseColumns: GridColDef[] = [
  {
    field: 'warehouse_number',
    headerName: 'Warehouse #',
    width: 120,
    type: 'string',
    editable: true,
  },
  {
    field: 'warehouse_description',
    headerName: 'Description',
    width: 200,
    type: 'string',
    editable: true,
  },
  {
    field: 'warehouse_manager',
    headerName: 'Manager',
    width: 150,
    type: 'string',
    editable: true,
  },
  {
    field: 'address',
    headerName: 'Address',
    width: 200,
    type: 'string',
    editable: true,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 150,
    type: 'string',
    editable: true,
  },
  {
    field: 'state',
    headerName: 'State',
    width: 100,
    type: 'string',
    editable: true,
  },
  {
    field: 'zip',
    headerName: 'ZIP',
    width: 100,
    type: 'string',
    editable: true,
  },
  {
    field: 'range',
    headerName: 'Range',
    width: 100,
    type: 'number',
    editable: true,
  },
  ...metadataColumns
];

export default warehouseColumns; 