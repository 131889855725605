import { Grid, Box } from "@mui/material";
import { useGridApiRef, GridRowSelectionModel, DataGridPremium, GridCellParams, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { useCreateDataMutation, useGetDataQuery } from "../../api/apiSliceV2";
import { apiRouteMap } from "../../routes/urls";
import { plpItemColumns } from "../columns/columns";
import ObjectID from "bson-objectid";
import CustomButton from "../../components/button/CustomButton";
import styles from "../detail.module.css";
import MaterialsToolbar from "./MaterialsToolbar";
import { useParams } from "react-router-dom";
import warehouseItemColumns from "../../warehouse/columns/WarehouseOrderCols";

const AddOrderItems = ({ 
    handleClose, 
    tagName,
    orderType,
    order
}:any) => {
  const {isLoading, isError} = useAppSelector((state:any) => state.api);
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]); 
  const [rows, setRows] = useState<any>([]);
  const apiRef = useGridApiRef();
  const {start_id} = useParams();
  const defaultParams = useMemo(() => {
    return {
      url: orderType === "prebuilt-order" ? 
        apiRouteMap.get("/prebuilt-orders/items").getDefaultItems() 
        : 
        apiRouteMap.get("/warehouse/orders/starts/default").url,
      params: {
        start_id
      },
      skip: !orderType
    }
  }, [orderType]);
  const {data: response} = useGetDataQuery(defaultParams);
  const [createData] = useCreateDataMutation();
  //const [updateData] = useUpdateDataMutation();
  useEffect(() => {
    if(response?.data && orderType === "prebuilt-order"){
      setRows(response?.data);
    }else if(response?.data){
      const foundOrder = response?.data?.find((item:any) => item?._id === order?._id);
      setRows(foundOrder?.order_items);
    }
  }, [response]);

  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
    console.log("orderType", orderType);
    if(orderType === "prebuilt-order"){
      setColumns(plpItemColumns);
    }else if(orderType === "order-fulfillment"){
      setColumns(warehouseItemColumns?.WarehouseOrderFulfillItemColumns);
    }else{
      setColumns(warehouseItemColumns?.WarehouseOrderRequestItemColumns);
    }
  }, [orderType]);

  const handleAddMaterial = (material:any) => {
    let {_id, ...newMaterial} = material;
    newMaterial = {
      ...newMaterial,
      id: new ObjectID().toHexString(),
      ...(orderType === "prebuilt-order" 
        ? {
            prebuilt_quantity: 0,
            segment_one: material?.cost_code?.split(".")[0],
            segment_two: material?.cost_code?.split(".")[1],
          } 
        : {
            requested_quantity: 0,
            segment_one: material?.cost_code?.split(".")[0],
            segment_two: material?.cost_code?.split(".")[1],
          }
      )
    }
    setRows([newMaterial, ...rows]);
    apiRef.current.selectRow(newMaterial?.id || newMaterial?._id);
  }

  const handleSubmit = async () => {
    try {
      // Add items to the order
      const url = orderType === "prebuilt-order" ? 
        apiRouteMap.get('/prebuilt-orders/items').create(order?._id) 
        : 
        apiRouteMap.get('/warehouse/orders/starts/items').create(order?._id);
      const formattedItems = rows
        ?.filter((item: any) => rowSelectionModel.includes(item?.id || item?._id))  // Filter out items not in the selection model
        ?.map((item: any) => {
          if(orderType === "prebuilt-order"){
            return {
              cost_type: item.cost_type,
              part_number: item.part_number,
              part_description: item.part_description,
              prebuilt_quantity: item.prebuilt_quantity || 0,
              unit_of_measure: item.unit_of_measure,
              segment_one: item?.segment_one,
              segment_two: item?.segment_two,
            }
          }
          return {
            cost_type: item.cost_type,
            part_number: item.part_number,
            part_description: item.part_description,
            requested_quantity: item.requested_quantity || 0,
            fulfilled_quantity: item.fulfilled_quantity || 0,
            unit_of_measure: item.unit_of_measure,
            segment_one: item?.segment_one,
            segment_two: item?.segment_two,
            notes: item?.notes,
          }  
      });
      orderType === "prebuilt-order" ?
        await createData({
          tagName,
          body: {
            items: formattedItems
          },
          url
        })
      :
        await createData({
          tagName,
          body: {
            items: formattedItems
          },
          url
        });
        if(!isError && !isLoading) handleClose();
    }catch(error:any){
      console.log(error);
    }
  };

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  }

  const processRowUpdate = useCallback((newRow: any) => {
    const id = newRow?._id || newRow?.id || newRow?.item_id;
    apiRef.current.selectRow(id);
    setRows((rows:any) => rows.map((row:any) => (row.id === newRow.id) ? newRow : row));
    return newRow;
  }, [apiRef]);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      columns: {
        columnVisibilityModel: {
          segment_one: orderType === "warehouse-order" ? false : true,
          segment_two: orderType === "warehouse-order" ? false : true,
          created_at: false,
          created_by: false,
          updated_at: false,
          updated_by: false
        },
      },
      rowGrouping: {
        model: ['segment_one']
      },
      sorting: {
        sortModel: [{field: '#GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD', sort: 'asc'}]
      }

    }
  });

  return (
    <Grid container sx={{ position: 'relative', height: "auto", width: "100%" }}>
      {/* Data Grid */}
      <Grid item xs={12} sx={{ height: "800px" }}>
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(0,0,0,0.25)",
            height: "100%",
            '& .MuiDataGrid-columnHeader': {
                whiteSpace: 'normal',
                lineHeight: '1.5',
                wordWrap: 'normal',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: "bold",
                whiteSpace: 'normal',
                lineHeight: '1',
                wordWrap: 'normal',
              },
          }}
          initialState={initialState} 
          defaultGroupingExpansionDepth={-1}
          apiRef={apiRef}
          checkboxSelection
          disableRowSelectionOnClick
          editMode="row"
          getRowId={(row:any) => row?._id || row?.id}
          density="compact"
          rows={rows || []}
          columns={columns || []}
          loading={isLoading}
          rowHeight={40}
          rowBufferPx={10}
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              return params.isEditable ? `${styles.editableCell}` : '';
            }}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          processRowUpdate={processRowUpdate}
          hideFooter
          slots={{
            toolbar: MaterialsToolbar as any
          }}
          slotProps={{
            toolbar: {
              order,
              orderType,
              tagName,
              handleAddMaterial,
              options: {
                showQuickFilter: true,
                showPrintOptions: false,
              }
            }
          }}
        />
      </Grid>

      {/* Submit Button */}
      <Grid item xs={12} sx={{justifyContent: "flex-end", my: 1}}>
        <Box sx={{display: "flex", justifyContent: "flex-end"}}>
          <CustomButton sx={{ marginLeft: 1 }} variant="contained" onClick={handleSubmit} component="label"  size="small" color="primary">
            Add Material
          </CustomButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddOrderItems;
