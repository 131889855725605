import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from "@mui/material";
import { GridRowSelectionModel, DataGridPremium } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import lotColumns from '../../projects/columns/lotColumns';
import CustomButton from '../../components/button/CustomButton';
import { useGetDataQuery, useCreateDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../../components/snackbar/snackbarSlice';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

const CreateWarehouseOrder = ({ 
  step,
  tagName,
  isUpdateMode,
  order,
  handleClose 
}: any) => {
  const { project_id, start_id } = useParams();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);
  const { isMobile } = useAppSelector((state: any) => state.layout);
  const [deliveryMethod, setDeliveryMethod] = useState<string>("DELIVERY");
  const [requestedDate, setRequestedDate] = useState<dayjs.Dayjs | null>(dayjs());

  const [orderNumber, setOrderNumber] = useState<string>(""); 
  const firstRef = useRef<any>(null);
  const cols = lotColumns?.useLotColumns();

  const queryParams = useMemo(() => (
    { 
      url: `starts/items/lots`, 
      params: {project_id, start_id 
      
    } 
  }), [project_id, start_id]);

  const {data: response, isLoading } = useGetDataQuery(queryParams);
  const [createData] = useCreateDataMutation();
  const {data: orderNumbers} = useGetDataQuery({url: `/dropdowns/prebuilt-order-numbers`, params: {project_id}});

  useEffect(() => {
    if(orderNumbers?.data) {
      setOrderNumber(orderNumbers?.data[0]);
    }
  }, [orderNumbers?.data]);

  useEffect(() => {
    if(response?.data) {
      setRowSelectionModel(response?.data?.map((item:any) => item?._id));
    }
  }, [response?.data, dispatch]);

  useEffect(() => {
    if(firstRef.current) firstRef.current.focus();
  },[]);

  useEffect(() => {
    if (isUpdateMode && order) {
      setDeliveryMethod(order.delivery_method);
      setRequestedDate(order.requested_date);
      setRowSelectionModel(order.lot_ids);
    }
  }, [dispatch, isUpdateMode, order]);

  const handleSubmit = async () => {
    try {
      const response = await createData({
        tagName,
        body: {
          requested_date: requestedDate,
          prebuilt_order_number: orderNumber,
          delivery_method: deliveryMethod,
          lot_ids: rowSelectionModel
        },
        url: step?.saveUrl(start_id)
      }).unwrap();
      handleClose();
      dispatch(showSnackbar({ message: response?.message || 'Data created successfuly', type: 'success' }));
    } catch (error:any) {
      const errorMessage = `Error status: ${error?.status} - ${error?.data?.message}`;
      dispatch(showSnackbar({ message: errorMessage || 'Data creation failed. Please try again', type: 'error' }));
    }
  };

  const handleSelectionModelChange = (rowSelectionModel:GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  };

  const handleDeliveryMethodChange = (e:any) => {
    const value = e.target.value;
    setDeliveryMethod(value);
  };

  const handleRequestedDateChange = (e:any) => {
    const value = e.target.value;
    setRequestedDate(value);
  };
 
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid container sx={{ justifyContent: "flex-start", alignItems: "center", padding: "1rem" }} gap={0}>
        <Grid container sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }} gap={1}>
          <Grid item sx={{ border: `1px solid ${theme.palette.primary.main} 0.9`, borderRadius: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <Grid item xs>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        formatDensity="dense"
                        label="Date"
                        value={requestedDate || dayjs()}
                        onChange={(newValue:any) => handleRequestedDateChange(newValue)}
                    />
                </LocalizationProvider>               
              </Grid> 
              <FormControl sx={{ width: 220}}>
                <InputLabel id="delivery-method-label">Delivery Method</InputLabel>
                <Select
                  labelId="delivery-method-label"
                  value={deliveryMethod}
                  onChange={(e: any) => handleDeliveryMethodChange(e)}
                  input={<OutlinedInput label="Delivery Method" />}
                  inputRef={firstRef}
                >
                  <MenuItem value="" disabled>
                    Select Delivery Method
                  </MenuItem>
                  <MenuItem value="DELIVERY">DELIVERY</MenuItem>
                  <MenuItem value="PICKUP">PICKUP</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ width: 220}}>
                <InputLabel id="order-number-label">Prebuilt Order Number</InputLabel>
                <Select
                  labelId="order-number-label"
                  value={orderNumber}
                  onChange={(e: any) => setOrderNumber(e.target.value)}
                  input={<OutlinedInput label="Prebuilt Order Number" />}
                >
                  {orderNumbers?.data?.map((item: any) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {/* Data Grid */}
        <Grid item xs={12} sx={{ height: "auto", maxHeight: "400px" }}>
          <DataGridPremium
            sx={{
              fontSize: 14,
              fontWeight: 500,
              border: "1px solid rgba(0,0,0,0.25)"
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  created_at: false,
                  created_by: false,
                  updated_at: false,
                  updated_by: false
                },
              },
            }}
            checkboxSelection
            disableRowSelectionOnClick
            editMode="row"
            getRowId={(row: any) => row?._id || row?.id || row?.item_id}
            density="compact"
            rows={response?.data || []}
            columns={cols?.map((col:any) => {
              return {
                ...col,
                editable: false
              }
            }) || []}
            loading={isLoading}
            rowHeight={60}
            rowBufferPx={10}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            hideFooter
          />
        </Grid>

        <Grid item xs={12} sx={{ justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomButton color="primary" sx={{ marginLeft: 1 }} variant="contained" onClick={handleSubmit} component="label" size="small">
              Create Order
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateWarehouseOrder;
