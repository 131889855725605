import React, { lazy } from 'react';
import { URLS } from './urls';
import Companies from '../organizational-units/Companies';
import CompanyDetail from '../organizational-units/detail/CompanyDetail';
import DivisionDetail from '../organizational-units/detail/DivisionDetail';

// Lazy load components
const Projects = lazy(() => import('../projects/Projects'));
const Starts = lazy(() => import('../starts/Starts'));
const Dashboard = lazy(() => import('../dashboard/Dashboard'));
const ReportView = lazy(() => import('../reports/office-reports/OfficeReports'));
const AssuranceDetail = lazy(() => import('../assurance/AssuranceDetail'));
//const ProjectDetail = lazy(() => import('../projects/detail/Detail'));
const StartDetail = lazy(() => import('../starts/detail/StartDetail'));
const FieldReports = lazy(() => import('../reports/field-reports/FieldReports'));
const WrappedItemView = lazy(() => import('../projects/project-items/WrappedItemView'));
const WarehouseOrders = lazy(() => import('../warehouse/tracker/WarehouseOrders'));
const WarehouseOrderDetail = lazy(() => import('../warehouse/detail/WarehouseOrderDetail'));
const FutureOrders = lazy(() => import('../assurance/future-orders/FutureOrders'));
const ProjectDetail = lazy(() => import('../projects/detail/Detail'));

export interface RouteType {
    id: string;
    name: string;
    path: string;
    component: React.ComponentType<any>;
}

// Define suites
export const suites: any = {
    root: {
        id: "root",
        name: "Root",
        path: URLS.root,
        component: Dashboard,
    },
    warehouse: {
        id: "warehouse",
        name: "Warehouse",
        routes: {
            warehouseOrders: {
                id: "warehouseOrders",
                name: "Warehouse Orders",
                path: URLS.warehouseOrders,
                component: WarehouseOrders,
            },
            warehouseOrderDetail: {
                id: "warehouseOrderDetail",
                name: "Warehouse Order Detail",
                path: URLS.warehouseOrderDetail,
                component: WarehouseOrderDetail,
            },
        }
    },
    app: {
        id: "app",
        name: "App",
        routes: {
            companies: {
                id: "companies",
                name: "Companies",
                path: URLS.companies,
                component: Companies,
            },
            companyDetail: {
                id: "companyDetail",
                name: "Company Detail",
                path: URLS.companyDetail,
                component: CompanyDetail,
            },
            divisionDetail: {
                id: "divisionDetail",
                name: "Division Detail",
                path: URLS.divisionDetail,
                component: DivisionDetail,
            },  
            projects: {
                id: "projects",
                name: "Projects",
                path: URLS.projects,
                component: Projects,    
            },
            projectDetail: {
                id: "project_detail",
                name: "Project Detail",
                path: URLS.projectDetail,
                component: ProjectDetail,    
            },
            proposal: {
                id: "proposalItems",
                name: "Proposal Items",
                path: URLS.proposal,
                component: WrappedItemView,
            },
            budget: {
                id: "budgetItems",
                name: "Budget Items",
                path: URLS.budget,
                component: WrappedItemView,
            },
            scheduleOfValues: {
                id: "scheduleOfValues",
                name: "Schedule of Values",
                path: URLS.scheduleOfValues,
                component: WrappedItemView,
            },
            contract: {
                id: "contractItems",
                name: "Contract Items",
                path: URLS.contract,
                component: WrappedItemView,
            },
            starts: {
                id: "start",
                name: "Start",
                path: URLS.starts,
                component: Starts,
            },
            startDetail: {
                id: "startDetail",
                name: "Start Detail",
                path: URLS.startDetail,
                component: StartDetail,
            },
            officeReports: {
                id: "reports",
                name: "Office Reports",
                path: URLS.reports,
                component: ReportView,
            },
            fieldReports: {
                id: "fieldReports",
                name: "Field Reports",
                path: URLS.fieldReports,
                component: FieldReports,
            },
        }
           
    },
    assurance: {
        id: "assurance",
        name: "Assurance",
        routes: {
            assuranceDetail: {
                id: "assuranceDetail",
                name: "Assurance Detail",
                path: URLS.assurance,
                component: AssuranceDetail,
            },
            futureOrders: {
                id: "futureOrders",
                name: "Future Orders",
                path: URLS.futureOrders,
                component: FutureOrders,
            },
        }
    },  
};

