import { useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { TagTypes } from "../../api/tagTypes";
import CustomGridToolbar from "../../components/grid/toolbars/CustomGridToolbar";
import ExpandableToolbar from "../../components/grid/toolbars/ExpandableToolbar";
import { DetailConfig } from "../../components/interfaces/DetailConfig";
import { apiRouteMap } from "../../routes/urls";
import warehouseColumns from "../../warehouse/columns/warehouseColumns";
import actions from "../actions/actions";
import segmentOneColumns from "../columns/costCodeSegmentOne";
import segmentTwoColumns from "../columns/costCodeSegmentTwo";
import costTypeColumns from "../columns/costTypeColumns";
import useDivisionColumns from "../columns/useDivisionColumns";
import employeeColumns from "../columns/employeeColumns";
import foremenColumns from "../columns/foremenColumns";
import jobTypeColumns from "../columns/jobTypeColumns";
import useCostCodeColumns from "../columns/useCostCodeColumns";
import warehouseActions from "../../warehouse/actions/actions";
import customerColumns from "../columns/customerColumns";
import useCompanyColumns from "../columns/companyColumns";

const useDivisionDetailConfig = () => {
    const {company, division} = useAppSelector((state:any) => state.auth);
    const divColumns = useDivisionColumns();
    const costCodeCols = useCostCodeColumns();
    const companyColumns = useCompanyColumns();
    const tabs:DetailConfig[] = useMemo(() => {
        const cols:DetailConfig[] = [  
            { 
                index: 0,
                route: "/job-types",
                tagType: TagTypes.JOB_TYPES,
                params: {division_id: division?._id},
                label: 'Job Types',
                steps: actions?.createJobType,
                header: undefined,
                detail: {
                    columns: jobTypeColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            }, 
            { 
                route: "/estimators",
                tagType: TagTypes.ESTIMATORS,
                params: {division_id: division?._id},
                index: 1,
                label: 'Estimators',
                steps: actions?.createEstimator,
                header: undefined,
                detail: {
                    columns: employeeColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
            { 
                route: "/supervisors",
                tagType: TagTypes.SUPERVISORS,
                params: {division_id: division?._id},
                index: 2,
                label: 'Supervisors',
                steps: actions?.createSupervisor,
                header: undefined,
                detail: {
                    columns: employeeColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },          
            { 
                index: 3,
                route: "/foremen",
                tagType: TagTypes.FOREMEN,
                params: {division_id: division?._id},
                label: 'Foremen',
                steps: actions?.createForeman,
                header: undefined,
                detail: {
                    columns: foremenColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
            { 
                index: 4,
                route: "/jc-accountants",
                tagType: TagTypes.ACCOUNTANTS,
                params: {division_id: division?._id},
                label: 'JC Accountants',
                steps: actions?.createJCAccountant,
                header: undefined,
                detail: {
                    columns: employeeColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
            { 
                index: 5,
                route: "/cost-types",
                tagType: TagTypes.COST_TYPES,
                params: {division_id: division?._id},
                label: 'Cost Types',
                steps: actions?.createCostType,
                header: undefined,
                detail: {
                    columns: costTypeColumns,
                    toolbar: ExpandableToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
            { 
                index: 6,
                route: "/cost-code-segment-one",
                tagType: TagTypes.COST_CODE_SEGMENT_ONE,
                params: {division_id: division?._id},
                label: 'Segment One',
                steps: actions?.createSegmentOne,
                header: undefined,
                detail: {
                    columns: segmentOneColumns,
                    toolbar: ExpandableToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
            { 
                index: 7,
                route: "/cost-code-segment-two",
                tagType: TagTypes.COST_CODE_SEGMENT_TWO,
                params: {division_id: division?._id},
                label: 'Segment Two',
                steps: actions?.createSegmentTwo,
                header: undefined,
                detail: {
                    columns: segmentTwoColumns,
                    toolbar: ExpandableToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
            { 
                index: 8,
                route: "/cost-codes",
                tagType: TagTypes.COST_CODES,
                params: {division_id: division?._id},
                label: 'Cost Codes',
                steps: actions?.createCostDistribution,
                header: undefined,
                detail: {
                    columns: costCodeCols,
                    toolbar: ExpandableToolbar,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
            { 
                index:  9,
                route: apiRouteMap.get("/warehouses").url,
                tagType: TagTypes.DATA,
                params: {company_id: company?._id},
                label: 'Warehouses',
                steps: warehouseActions?.createWarehouse,
                header: undefined,
                detail: {
                    columns: warehouseColumns,
                    toolbar: CustomGridToolbar,
                    toolbarProps: {
                        options: {
                            showPrintOption: true,
                            showQuickFilter: true,
                            showExportOption: false,
                        }
                    },
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
                panel: {
                    columns: [],
                    toolbar: undefined,
                    toolbarProps: {},
                    footer: undefined,
                    footerProps: {},
                    actions: {
                        expandable: false,
                        exportable: false,
                        deletable: true,
                        editable: true,
                    },
                    hasDocuments: false,
                },
            },
        ];
        return cols;    

    }, [divColumns, costCodeCols, companyColumns, customerColumns]);

    return {
        tabs,
    };
};

export default useDivisionDetailConfig;