import { WIDTHS } from "../../../utils/columnUtil";
import metadataColumns from "../../components/columns/metadataColumns";
import { STATUS_MAP } from "../../projects/types/status";
import { Select, MenuItem, Typography, Link as MuiLink } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { useParams, useNavigate } from "react-router-dom";

const useDivisionColumns = ():GridColDef[] => {
    const {company_id} = useParams();
    const navigate = useNavigate();

    const columns:GridColDef[] = [
        {
            field: 'division_number',
            headerName: 'Division',
            type: 'number',
            width: WIDTHS.XS,
            filterable: true,
            editable: false,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params:any) => {
                return (
                    <MuiLink   
                        component="button"
                        onClick={() => navigate(`/companies/${company_id}/divisions/${params.row._id}`)}
                        sx={{ 
                            fontWeight: 600,
                            textDecoration: 'none',
                            '&:hover': { 
                                cursor: 'pointer',
                                textDecoration: 'underline' 
                            }
                        }}
                    >
                    {params.value}
                    </MuiLink>
                );
            },
        },
        {
            field: 'division_description',
            headerName: 'Description',
            headerAlign: 'left',
            align: 'left',
            type: 'string',
            width: WIDTHS.LARGE,
            filterable: true,
            editable: true,
        },
        {
            field: 'division_manager',
            headerName: 'Manager',
            headerAlign: 'left',
            align: 'left',
            type: 'string',
            width: WIDTHS.LARGE,
            filterable: true,
            editable: true,
        },
        {
            field: 'division_location',
            headerName: 'Location',
            headerAlign: 'left',
            align: 'left',
            type: 'string',

            
            width: WIDTHS.LARGE,
            filterable: true,
            editable: true,
        },
        {
            field: 'division_revision_number',
            headerName: 'Revision',
            headerAlign: 'left',
            align: 'left',
            type: 'number',
            width: WIDTHS.XS,
            filterable: true,
            editable: true,
        },
        {
            field: 'division_revision_description',
            headerName: 'Revision Description',
            headerAlign: 'left',
            align: 'left',
            type: 'string',
            width: WIDTHS.LARGE,
            filterable: true,
            editable: true,
        },
        {
            field: 'division_revision_date',
            headerName: 'Revision Date',
            headerAlign: 'left',
            align: 'left',
            type: 'date',
            width: WIDTHS.SMALL,
            filterable: true,
            editable: true,
            valueGetter: (value:any) => value ? new Date(value) : undefined,
    
        },
        {
            field: 'division_status',
            headerName: 'Status',
                type: 'string',
            width: WIDTHS.SMALL,
            headerAlign: 'left',
            align: 'left',
            filterable: true,
            editable: true,
            // Optional: Custom rendering or color based on status
            renderEditCell: (params: GridRenderCellParams) => (
                <Select
                  value={params.row?.status}
                  onChange={(event) => {
                    params.api.setEditCellValue({ 
                      id: params.id, 
                      field: params.field, 
                      value: event.target.value 
                    });
                  }}
                  fullWidth
                  id={String(params.id)}
                  size="small"
                  sx={{ width: '100%'}}
                >
                  {Object.values(STATUS_MAP).map((status) => (
                    <MenuItem 
                      key={status.value}
                      value={status.value}
                      sx={{ color: status.color}}
                    >
                      <Typography sx={{ color: status.color, fontWeight: 600, fontSize: 14 }}>{status.label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              ),
        },
        ...metadataColumns
    ];
    return columns;
}

export default useDivisionColumns;