import {ReactComponent as Logo} from '../../assets/test.svg';
import {ReactComponent as StartSuiteLogo} from '../../assets/sss.svg';
import {ReactComponent as StagingOrders} from '../../assets/StagingOrders.svg';
import {ReactComponent as Warehouse} from '../../assets/warehouse2.svg';
import {ReactComponent as OrgUnits} from '../../assets/organizational-units.svg';
import ConstructionSharpIcon from '@mui/icons-material/ConstructionSharp';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import AssuredWorkloadSharpIcon from '@mui/icons-material/AssuredWorkloadSharp';
import ObjectID from "bson-objectid";
import WarehouseSharpIcon from '@mui/icons-material/WarehouseSharp';
import LanSharpIcon from '@mui/icons-material/LanSharp';
import { URLS } from '../routes/urls';

export const pageLinks = {
    admin: [
        {id: String(new ObjectID()), title: 'Organizational Units', desc: "Manage Organizational Units", imgSrc: OrgUnits, link: URLS.companies, icon: <LanSharpIcon fontSize="large" />},
    ],
    app: [
        {id: String(new ObjectID()), title: 'Projects', desc: "Manage Projects", imgSrc: Logo, link: URLS.projects, icon: <ConstructionSharpIcon fontSize="large" />},
        {id: String(new ObjectID()), title: 'Starts', desc: "Manage Subjobs", imgSrc: StartSuiteLogo,link: URLS.starts, icon: <PlayArrowSharpIcon fontSize="large" />},
        {id: String(new ObjectID()), title: 'Assurance', desc: "Assure Starts", imgSrc: StagingOrders,link: URLS.futureOrders, icon: <AssuredWorkloadSharpIcon fontSize="large" />},  
    ],
    warehouse: [
        {id: String(new ObjectID()), title: 'Warehouse', desc: "View/Manage Warehouse", imgSrc: Warehouse, link: URLS.warehouseOrders, icon: <WarehouseSharpIcon fontSize="large" />},
    ]
}
