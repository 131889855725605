import { useState } from "react";
import CustomButton from "../../components/button/CustomButton";
import withCustomGridToolbar from "../../components/grid/toolbars/withCustomGridToolbar";
import { Grid, Box, ClickAwayListener } from "@mui/material";
import WarehouseSearch from "../../warehouse/detail/warehouse-search/WarehouseSearch";

interface MaterialsToolbarProps {
  options: {
    showQuickFilter: boolean;
    showPrintOptions: boolean;
  } & Record<string, boolean>;
  order_id?: string;
  orderType?: string;
  tagName?: string;
  handleAddMaterial?: (material: any) => void;
}

const MaterialsToolbar = (props: MaterialsToolbarProps) => {
  const { 
    order_id, 
    orderType, 
    tagName, 
    handleAddMaterial 
  } = props;
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const handleClickAway = () => {
    setShowSearch(false);
  };

  // Define toolbar actions
  const toolbarActions = [
    {
        key: 'MaterialsSearch',
        component: (
            <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                    <Grid 
                        item 
                        xs 
                        sx={{ 
                            display: "flex", 
                            justifyContent: "flex-start", 
                            mb: 2 
                        }}>  
                        <CustomButton
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => setShowSearch(!showSearch)}
                        >
                            {showSearch ? 'Hide Search' : 'Search Materials'}
                        </CustomButton>
                    </Grid>

                    {/* Floating Search Results */}
                    {showSearch && (
                        <Box sx={{
                            position: 'absolute',
                            top: 60,
                            left: 0,
                            width: '400px',
                            maxHeight: '600px',
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            boxShadow: 3,
                            zIndex: 99,
                            overflow: 'auto'
                        }}>
                            <WarehouseSearch
                                order_id={order_id}
                                orderType={orderType}
                                handleClose={() => setShowSearch(false)}
                                tagName={tagName}
                                handleAddMaterial={handleAddMaterial}
                            />
                        </Box>
                    )}
                </div>
            </ClickAwayListener>
        ),
        order: 1
    },
    
  ].filter(Boolean);

  // Create enhanced toolbar with actions
  const EnhancedToolbar = withCustomGridToolbar(toolbarActions);

  return <EnhancedToolbar {...props} />;
};

export default MaterialsToolbar;
