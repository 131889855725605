import { useEffect, useMemo, useState } from 'react';
import { Grid, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import ObjectID from 'bson-objectid';
import { useAppSelector } from '../../../../app/hooks';
import { AutocompleteOptions } from '../../../../utils/interfaces';
import { useGetDataQuery, useCreateDataMutation } from '../../../api/apiSliceV2';
import CustomButton from '../../button/CustomButton';
import { apiRouteMap } from '../../../routes/urls';
import { GridToolbarExport, GridToolbarContainer, GridToolbarFilterButton  } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';

const CostCodeToolbar = (props: any) => {
    const { context, tagName  } = props;
    const [formData, setFormData] = useState<any>({});
    const [formErrors] = useState<any>({});
    const {company, division} = useAppSelector((state:any) => state.auth);
    const theme = useTheme();
    const {company_id, division_id} = useParams();
    // Query Params
    const params = useMemo(() => ({
        division_id: division?._id
    }),[division]);
    // Query Data
    const { data: segmentOneData } = useGetDataQuery({ url: '/cost-code-segment-one', params, tagName});
    const { data: segmentTwoData } = useGetDataQuery({ url: '/cost-code-segment-two', params, tagName });
    const { data: costTypesData } = useGetDataQuery({ url: '/cost-types', params, tagName });
    const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOptions>({});
    // Create Data
    const [createData] = useCreateDataMutation();

    // Set Autocomplete Options
    useEffect(() => {
        if (segmentOneData && segmentTwoData && costTypesData) {
            setAutocompleteOptions({
                segment_one: {
                    data: segmentOneData?.data || [],
                    options: segmentOneData?.data.map((item: any) => `${item.segment_one}-${item.description}`) || [], 
                },
                segment_two: {
                    data: segmentTwoData?.data || [],
                    options: segmentTwoData?.data.map((item: any) => `${item.segment_two}-${item.description}`) || [], 
                },
                cost_type: {
                    data: costTypesData?.data || [],
                    options: costTypesData?.data.map((item: any) => `${item.cost_type}-${item.description}`) || [], 
                },
            });
        }
    }, [segmentOneData, segmentTwoData, costTypesData]);

    // Handle form input change
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    // Handle date change
    const handleDateChange = (field: string, newValue: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            [field]: newValue,
        }));
    };

    // Handle save
    const handleSave = async () => {
        try {
            // Find autocomplete options
            const segment_one = segmentOneData?.data?.find((item:any) => `${item.segment_one}-${item.description}` === formData?.segment_one);
            const segment_two = segmentTwoData?.data?.find((item:any) => `${item.segment_two}-${item.description}` === formData?.segment_two);
            const cost_type = costTypesData?.data?.find((item:any) => `${item.cost_type}-${item.description}` === formData?.cost_type);

            let jsonData = {
                ...formData,
                _id: new ObjectID(),
                company_id: company_id || company?._id,
                division_id: division_id || division?._id, 
                segment_one_id: segment_one?._id,
                segment_two_id: segment_two?._id,
                cost_type_id: cost_type?._id,
            };

            // Create data
            await createData({
                tagName,
                url: apiRouteMap?.get(context?.route).url(),
                body: jsonData,
            }).unwrap();
            setFormData({});
        } catch (err:any) {
            // Show error
            console.error(err?.data?.message)
        } 
    };

    console.log(context)
    return (
        <Grid container sx={{ width: '100%' }}>
            <Grid container justifyContent="start" alignItems="start" spacing={1} sx={{ padding: 1}}>
                {context && context?.detail?.columns?.filter((column: any) => column?.type !== 'actions' && column.editable)?.map((column: any) => (
                    <Grid item lg={2} md={4} sm={6} xs={12} key={column?.field}>
                        {column?.type === 'date' ? (
                            <LocalizationProvider >
                                <DatePicker
                                    sx={{ mt: 1.5, mb: 2, width: 'auto', minWidth: 120 }}
                                    label={column?.headerName}
                                    value={formData[column?.field] || dayjs()}  // Use dayjs() to initialize with the current date
                                    onChange={(newValue) => handleDateChange(column?.field, newValue)}
                                />
                            </LocalizationProvider>
                        ) : (autocompleteOptions?.[column?.field] && 
                            context?.route?.includes("cost-codes")) ? (
                            <Autocomplete
                                freeSolo
                                multiple={column?.type === 'array'}  // Enable multiple if the field is an array
                                fullWidth
                                options={autocompleteOptions?.[column?.field]?.options || []}
                                getOptionLabel={(option: any) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={column?.headerName}
                                        variant="standard"
                                        error={formErrors && !!formErrors?.[column?.field]}
                                        helperText={formErrors && formErrors[column?.field] ? 'required' : ''}
                                    />
                                )}
                                value={formData[column?.field] || (column?.type === 'array' ? [] : '')} 
                                onChange={(_, newValue) => {

                                    setFormData((prevState: any) => ({
                                        ...prevState,
                                        [column?.field]: column?.type === 'array'
                                            ? newValue  
                                            : newValue || '',  
                                    }));
                                }}
                            />
                        ) : (
                            <TextField
                                label={column?.headerName}
                                fullWidth
                                type={column?.type === 'number' ? 'number' : 'text'}
                                name={column?.field}
                                value={formData[column?.field] || ''}
                                onChange={handleChange}
                                variant="standard"
                                error={formErrors && !!formErrors?.[column?.field]}
                                helperText={formErrors && formErrors?.[column?.field] ? 'required' : ''}
                            />
                        )}
                    </Grid>
                ))}
                <Grid item xs sx={{ padding: "1rem", marginTop: 0.75}}>
                    <CustomButton sx={{fontWeight: 600, border: `2px solid ${theme.palette.primary.main}`}} variant="outlined" onClick={handleSave} size="small" color="primary">Create</CustomButton>
                </Grid>
            </Grid>
          
            <GridToolbarContainer sx={{ fontWeight: 600 }}>
                <GridToolbarFilterButton 
                    slotProps={{
                        button: {
                            sx: {fontWeight: 600}
                        }
                    }} 
                />
                <GridToolbarExport 
                    slotProps={{
                        button: {
                            sx: {fontWeight: 600}
                        }
                    }}
                />
            </GridToolbarContainer>
        </Grid>
    );
};

export default CostCodeToolbar;
