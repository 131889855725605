import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, TextField } from "@mui/material";
import { useAppSelector } from '../../../app/hooks';
import { useParams } from 'react-router-dom';
import { DataGridPremium, GridCellParams, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { plpItemColumns } from '../columns/columns';
import styles from '../detail.module.css';
import SovSelection from './SovSelection';
import { useCreateDataMutation, useGetDataQuery } from '../../api/apiSliceV2';
import { apiRouteMap } from '../../routes/urls';
import WarehouseSearchToolbar from '../../warehouse/detail/warehouse-search/WarehouseSearchToolbar';

const CreateOrder = ({ 
    step, activeStep,
    completed, 
    handleClose, 
    jobType, 
    tagName,
    getJobTypeComponent, 
    getModeSelect
}:any) => {
  const {isLoading, isError} = useAppSelector((state:any) => state.api);
  const {project_id, start_id} = useParams();
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]); 
  const [rows, setRows] = useState<any>([]);
  const [sovIDs, setSovIDs] = useState<string[]>([]);
  const [orderName, setOrderName] = useState<string>("");
  const [orderNumber, setOrderNumber] = useState<string>("");
  const apiRef = useGridApiRef();
  const [createData] = useCreateDataMutation();
  const {data: response} = useGetDataQuery({url: apiRouteMap.get("/prebuilt-orders/items").getDefaultItems()});
  
  useEffect(() => {
   if(response?.data){
      setRows(response?.data);
    }  
  }, [response]);

  const handleSubmit = async () => {
    try {

      let data = {
        order_name: orderName,
        order_number: Number(orderNumber || 0),
        schedule_of_values_ids: sovIDs,
      }
      
      const url = step?.idType === "project_id" ? step?.saveUrl(project_id) : step?.saveUrl(start_id);
      const response = await createData({
        //tagName,
        body: data,
        url
      }).unwrap();
      if(response.data){
        //Create default selected items
        const url = (step?.orderType === "pre-built order") ?  
         '/prebuilt-orders/items'
        :
          `/warehouse/orders/items?order_id=${response?.data?.warehouseOrder?._id}`;


        const formattedItems = rows
          ?.filter((item: any) => rowSelectionModel.includes(item?.id))  // Filter out items not in the selection model
          ?.map((item: any) => {
            if(step?.orderType === "pre-built order"){
              return {
                cost_type: item.cost_type,
                part_number: item.part_number,
                part_description: item.part_description,
                prebuilt_quantity: item.prebuilt_quantity || 0,
                unit_of_measure: item.unit_of_measure,
                segment_one: item?.segment_one,
                segment_two: item?.segment_two,
              }
            }
            return {
              cost_type: item.cost_type,
              part_number: item.part_number,
              part_description: item.part_description,
              order_quantity: item.prebuilt_quantity || 0 ,  
              unit_of_measure: item.unit_of_measure,
              segment_one: item?.segment_one,
              segment_two: item?.segment_two,
            }  
        });
        step?.orderType === "pre-built order" ?

        await createData({
          tagName,
          body: {prebuilt_order_ids: response?.data?.map((item:any) => item?._id), items:formattedItems},
          url
        })
        :
        await createData({
          tagName,
          body: formattedItems,
          url
        });
        
      } 
      if(!isError && !isLoading) handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSovIdChange = (event:any) => {
    const values = event.target.value;
    setSovIDs(values);
  };

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelectionModel);
  }

  const processRowUpdate = useCallback((newRow: any) => {
    const id = newRow?._id || newRow?.id || newRow?.item_id;
    apiRef.current.selectRow(id);
    setRows((rows:any) => rows.map((row:any) => (row.id === newRow.id) ? newRow : row));
    return newRow;
  }, [apiRef]);

  return (
    <Grid container justifyContent="center" sx={{justifyContent: "flex-start", alignItems: "center", height: "auto", width: "100%"}}>
      {/* Mode and Job Type Select */}
      <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-start"}}>
        {getModeSelect()}
        {getJobTypeComponent()}
        <TextField size="small" label="Order Name" value={orderName} onChange={(e:any) => setOrderName(e.target.value)} />
        <TextField size="small" label="Order Number" value={orderNumber} onChange={(e:any) => setOrderNumber(e.target.value)} />
      </Grid>

      {/* Sov Selection */}
      <Grid item xs={12}>
        <SovSelection  handleSovIdChange={handleSovIdChange} jobType={jobType} /> 
      </Grid>

      {/* Data Grid */}
      <Grid item xs={12} sx={{height: "auto"}}>
        <DataGridPremium
          sx={{
            fontSize: 14, 
            fontWeight: 500, 
            border: "1px solid rgba(0,0,0,0.25)",
            height: "100%"
          
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                created_at: false,
                created_by: false,
                updated_at: false,
                updated_by: false
              },
            },
            rowGrouping: {
              model: ['segment_one']
            },
            sorting: {
              sortModel: [{field: '#GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD', sort: 'asc'}]
            }
  
          }} 
          defaultGroupingExpansionDepth={-1}
          apiRef={apiRef}
          checkboxSelection
          disableRowSelectionOnClick
          editMode="row"
          getRowId={(row:any) => row?._id || row?.id}
          density="compact"
          rows={rows || []}
          columns={plpItemColumns || []}
          loading={isLoading}
          rowHeight={40}
          rowBufferPx={10}
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            return params.isEditable ? `${styles.editableCell}` : '';
          }}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          processRowUpdate={processRowUpdate}
          //experimentalFeatures={{ clipboardPaste: true }}
          slots={{
            toolbar: WarehouseSearchToolbar as any
          }}
          slotProps={{
            toolbar: {
              orderType: 'prebuilt-order',
              order_id: "",
            }
          }}
        />
      </Grid>

      {/* Submit Button */}
      <Grid item xs={12} sx={{justifyContent: "flex-end", my: 1}}>
        <Box sx={{display: "flex", justifyContent: "flex-end"}}>
          {!completed[activeStep] && (
            <Button sx={{marginLeft: 1}} variant="contained" onClick={handleSubmit}  component="label">
              Create Order
            </Button>
          )}
          {completed[activeStep] && (
            <Button sx={{marginLeft: 1}} variant="contained" onClick={handleClose}  component="label">
              Close
            </Button>
          )} 
        </Box>
      </Grid>

    </Grid>
  );
};

export default CreateOrder;
