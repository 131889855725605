import companyColumns from "../columns/companyColumns";
import segmentOneColumns from "../columns/costCodeSegmentOne";
import customerColumns from "../columns/customerColumns";
import employeeColumns from "../columns/employeeColumns";
import jobTypeColumns from "../columns/jobTypeColumns";
import segmentTwoColumns from "../columns/costCodeSegmentTwo";
import costTypeColumns from "../columns/costTypeColumns";
import useCostCodeColumns from "../columns/useCostCodeColumns";
import { TagTypes } from "../../api/tagTypes";
import foremenColumns from "../columns/foremenColumns";
import SelectForm from "../../components/forms/SelectForm";
import tradeColumns from "../columns/tradeColumns";

// Create a Company
const createCompany = [
    {
        route: "/companies",
        tagType: TagTypes.COMPANIES,
        label: "Add Company",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/companies',
        getColumns: () => companyColumns,
        isOptional: false
    },
];

// Create a Company
const createDivision = [
    {
        route: "/divisions",
        tagType: TagTypes.DIVISIONS,
        label: "Add Division",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/divisions',
        getColumns: () => [],
        isOptional: false
    },
];

const createTrade = [
    {
        route: "/trades",
        tagType: TagTypes.TRADES,
        label: "Add Trade",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        saveUrl: () => '/trades',
        getColumns: () => tradeColumns,
        isOptional: false
    },
]

// Create a Company
const createJobType = [
    {
        route: "/job-types",
        label: "Create Job Type",
        Component: SelectForm, 
        tagType: TagTypes.JOB_TYPES,
        actionType: "submit",
        idType: "",
        saveUrl: () => '/job-types',
        getColumns: () => jobTypeColumns,
        isOptional: false
    },
];

// Create a Company
const createCustomer = [
    {
        route: "/customers",
        label: "Create Customer",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        tagType: TagTypes.CUSTOMERS,
        saveUrl: () => '/customers',
        getColumns: () => customerColumns,
        isOptional: false
    },
];

// Create a Company
const createSupervisor = [
    {
        route: "/supervisors",
        label: "Create Supervisor",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        tagType: TagTypes.SUPERVISORS,
        saveUrl: () => '/supervisors',
        getColumns: () => employeeColumns,
        isOptional: false
    },
];

// Create a Company
const createEstimator = [
    {
        route: "/estimators",
        label: "Create Estimator",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        tagType: TagTypes.ESTIMATORS,
        saveUrl: () => '/estimators',
        getColumns: () => employeeColumns,
        isOptional: false
    },
];

// Create a Company
const createForeman = [
    {
        route: "/foremans",
        label: "Create Foreman",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        tagType: TagTypes.FOREMEN,
        saveUrl: () => '/foremen',
        getColumns: () => foremenColumns,
        isOptional: false
    },
];

// Create a Company
const createJCAccountant = [
    {
        route: "/jc-accountants",
        label: "Create JC Accountant",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        tagType: TagTypes.ACCOUNTANTS,
        saveUrl: () => '/jc-accountants',
        getColumns: () => employeeColumns,
        isOptional: false
    },
];

const createSegmentOne = [
    {
        route: "/cost-code-segment-one",
        label: "Create Segment One",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        tagType: TagTypes.COST_CODE_SEGMENT_ONE,
        saveUrl: () => '/cost-code-segment-one',
        getColumns: () => segmentOneColumns,
        isOptional: false
    },
];

const createSegmentTwo = [
    {
        route: "/cost-code-segment-two",
        label: "Create Segment Two",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        tagType: TagTypes.COST_CODE_SEGMENT_TWO,
        saveUrl: () => '/cost-code-segment-two',
        getColumns: () => segmentTwoColumns,
        isOptional: false
    },
];

const createCostType = [
    {
        route: "/cost-types",
        label: "Create Cost Type",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        tagType: TagTypes.COST_TYPES,
        saveUrl: () => '/cost-types',
        getColumns: () => costTypeColumns,
        isOptional: false
    },
];

const createCostDistribution = [
    {
        route: "/cost-code-segment-two",
        label: "Create Cost Code",
        Component: SelectForm, 
        actionType: "submit",
        idType: "",
        tagType: TagTypes.COST_CODES,
        saveUrl: () => '/cost-codes',
        getColumns: () => () => {
            const columns = useCostCodeColumns();
            return columns;
        },
        isOptional: false
    },
];

export default {
    createCompany,
    createDivision,
    createTrade,
    createJobType,
    createCustomer,
    createSupervisor,
    createEstimator,
    createForeman,
    createJCAccountant,
    createSegmentOne,
    createSegmentTwo, 
    createCostType,
    createCostDistribution
}