import React, { useCallback, useEffect, useState } from 'react';
import { 
  Box, 
  Button, 
  Grid, 
  TextField, 
  InputAdornment, 
  MenuItem, 
  Typography, 
  Select 
} from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { clearFormErrors } from '../../projects/projectSlice';
import { 
  DataGridPremium, 
  GridEventListener, 
  GridRowEditStopReasons, 
  GridRowId, 
  GridRowModes, 
  GridRowModesModel, 
  GridRowSelectionModel, 
  useGridApiRef 
} from '@mui/x-data-grid-premium';
import ObjectID from 'bson-objectid';
import { useParams } from 'react-router-dom';
import { setErrorState } from '../stepper/stepperSlice';
import { useCreateDataMutation } from '../../api/apiSliceV2';
import { showSnackbar } from '../snackbar/snackbarSlice';
import SelectMenu from './SelectMenu';
import { evaluate } from 'mathjs';
import CustomButton from '../button/CustomButton';


const Clipboard = ({
  tagName, 
  step, 
  columns, 
  activeStep, 
  completed, 
  handleClose, 
  setCompleted, 
  jobType, 
  selectedSov, 
  setSelectedSov,
  sovIDs,
  getJobTypeComponent,
  getModeSelect
}: any) => {
  const { selectedItem, isLoading } = useAppSelector((state: any) => state.stepper);
  const {company_id, division_id, project_id, budget_id, proposal_id, contract_id } = useParams();
  const [rows, setRows] = useState<any[]>([]);
  const [numRowsToAdd, setNumRowsToAdd] = useState<number>(1);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const { company, division } = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();
  const [createData] = useCreateDataMutation();
  const [selectedColumn, setSelectedColumn] = useState("");
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    const checkPermissions = async () => {
      const queryOpts: any = { name: 'clipboard-read', allowWithoutGesture: false };
      const permissionStatus: any = await navigator.permissions.query(queryOpts);
      if (permissionStatus && permissionStatus.state === 'prompt') {
        navigator.clipboard.readText().catch((error: any) => console.log(error));
      }
    }
    checkPermissions();
  }, []);

  const addItem = () => {
    const newRow = {
      _id: new ObjectID().toHexString(),
    };
    setRows((currentRows: any) => [...currentRows, newRow]);
  };

  const addMultipleItems = () => {
    for (let i = 0; i < numRowsToAdd; i++) {
      addItem();
    }
  };

  useEffect(() => {
    addItem();
  }, []);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = useCallback((newRow: any) => {
    setRows((rows: any) => rows.map((row: any) => (row._id === newRow._id) ? newRow : row));
    return newRow;
  }, []);

  const handleRowModesModelChange = (rowModesModel: GridRowModesModel) => {
    setRowModesModel(rowModesModel);
    console.log(rowModesModel)
  }

  const cancelAllEditModes = useCallback(() => {
    const rowIds = apiRef.current.getAllRowIds();
    rowIds.forEach((rowId: GridRowId) => {
      const rowMode = apiRef.current.getRowMode(rowId);
      if (rowMode === GridRowModes.Edit) {
        apiRef.current.stopRowEditMode({ id: rowId });
      }
    });
  }, [apiRef]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      cancelAllEditModes();
      await new Promise(resolve => setTimeout(resolve, 0));
      const currentRows = apiRef.current.getRowModels();
      const filtered = Array.from(currentRows.values())
      ?.filter((row: any) => {
        //Filter blank rows
        console.log("Row: ", Object.keys(row));
        return Object.entries(row).some(([key, value]) => value !== undefined && value !== null && value !== '' && key !== '_id');
      })
      
      ?.map((row: any) => {
        const key = `${row?.plan}-${row?.elevation}-${row?.option}`.toUpperCase();
        const schedule_of_values_id = sovIDs?.get(key);
        return {
          project_id,
          company_id: company_id || company?._id,
          division_id: division_id || division?._id,
          schedule_of_values_id,
          job_type_id: jobType?._id,
          ...row
        };
      });

      if (filtered.length === 0) {
        dispatch(setErrorState(true));
        dispatch(showSnackbar({ message: "Submission failed, no data provided.", type: "error" }));
        return;
      }

      let id: string;
      switch (step?.idType) {
        case 'project_id':
          id = project_id || "";
          break;
        case 'budget_id':
          id = budget_id || selectedItem?._id;
          break;
        case 'proposal_id':
          id = proposal_id || selectedItem?._id;
          break;
        case 'contract_id':
          id = contract_id || selectedItem?._id;
          break;
        default:
          id = project_id || "";
          break;
      }

      const submitBatch = async (batch: any) => {
        const response = await createData({
          url: step?.saveUrl(id),
          body: batch,
          tagName
        }).unwrap();
        return response;
      }

      const batchSize = 100;
      for (let i = 0; i < filtered?.length; i += batchSize) {
        const batch = filtered?.slice(i, i + batchSize);
        await submitBatch(batch);
      }

      dispatch(showSnackbar({ message: 'Data created successfully', type: 'success' }));
      setCompleted({ [activeStep]: true });
      dispatch(clearFormErrors());
    } catch (error) {
      console.log("error", error)
      dispatch(showSnackbar({ message: "Batch submission failed, please review the data for errors.", type: "error" }));
    }
  };

  const handlePasteStart = async () => {
    try {
      // Clear existing rows before paste
      setRows([]);
    } catch (error) {
      console.log("Error preparing for clipboard paste");
    }
  };

  const handlePasteEnd = async () => {
    try {
      // Get the pasted data from the grid
      const currentRows = apiRef.current.getRowModels();
      const pastedRows = Array.from(currentRows.values());
      
      // Filter out empty rows and add IDs
      const validRows = pastedRows
        .filter((row: any) => {
          // Check if row has any data
          return Object.keys(row).some(key => 
            key !== '_id' && row[key] !== undefined && row[key] !== null && row[key] !== ''
          );
        })
        .map((row: any) => ({
          ...row,
          _id: row._id || new ObjectID().toHexString()
        }));

      // If no valid rows were pasted, add one empty row
      if (validRows.length === 0) {
        addItem();
      } else {
        setRows(validRows);
      }

    } catch (error) {
      console.log("Error processing pasted data:", error);
      // Ensure there's at least one row if an error occurs
      setRows([{ _id: new ObjectID().toHexString() }]);
    }
  };

  const handleApplySov = useCallback(() => {
    const sov = sovIDs?.get(selectedSov);
    if (!sov) return;

    setRows((prevRows: any[]) =>
      prevRows.map((row: any) => {
        if (rowSelectionModel.includes(row?._id)) {
          return {
            ...row,
            schedule_of_values_id: sov?._id,
            plan: sov?.plan,
            elevation: sov?.elevation,
            option: sov?.option,
            option_code: sov?.option_code,
          };
        }
        return row;
      })
    );

    // Force the grid to refresh
    apiRef.current.updateRows(rows);
  }, [rowSelectionModel, selectedSov, sovIDs, rows]);

  const handleColumnChange = (event:any) => {
    setSelectedColumn(event.target.value);
  };

  const handleValueChange = (event:any) => {
    setNewValue(event.target.value);
  };

  const handleDeleteRow = () => {
    setRows(rows?.filter((row:any) => !rowSelectionModel.includes(row?._id)));
  }

  const parseMathExpression = (value: string, originalValue: number) => {
    try {
      // Replace 'x' with the original value
      const expression = value.replace(/x/g, originalValue.toString());
      // Evaluate the expression
      const result = evaluate(expression);
      if(isNaN(Number(result))) return value;

      return Number(result).toFixed(2);
    } catch (error) {
      console.error("Error parsing expression:", error);
      // If there is an error in parsing, return the original value
      return originalValue;
    }
  };

  const updateSelectedRows = () => {
    let updatedRows = [...rows];
  
    rowSelectionModel.forEach((rowId:GridRowId) => {
      const rowToUpdate = updatedRows.find((row) => row._id === rowId);
      if (rowToUpdate) {
        const oldValue = rowToUpdate[selectedColumn];
        if(selectedColumn === "proposal_price"){
          console.log(selectedColumn);

        }
        const updatedValue =  (selectedColumn === "proposal_price") ?
          parseMathExpression(newValue, oldValue)
          :
          newValue;
        const updatedRow = { ...rowToUpdate, [selectedColumn]: updatedValue };
        const processedRow = processRowUpdate(updatedRow);
        updatedRows = updatedRows.map((row) => (row._id === rowId ? processedRow : row));
      }
    });
  
    setRows(updatedRows);
  };

  const clearRows = () => {
    setRows([]);
  }
 
  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid container justifyContent="center" sx={{ width: "100%" }} spacing={0}>
        <Grid item xs={12}>
          <Grid 
            container  
            sx={{
              display: "flex", 
              alignItems: "center",
              
            }}
            spacing={1}
          >
            <Grid item>
              {getModeSelect()}
            </Grid>
            <Grid item>
              {getJobTypeComponent()}
            </Grid>
            {step?.options?.sov && (
              <Grid item>
                <SelectMenu 
                  options={Array.from(sovIDs.keys())} 
                  label="Select Schedule of Values" 
                  selectedValue={selectedSov} 
                  onChange={(selected: string) => setSelectedSov(selected)} 
                  onApply={handleApplySov} 
                />
              </Grid>
            )}
            <Grid item >
              <TextField
                label="Add/Remove Rows"
                type="number"
                size="small"
                value={numRowsToAdd}
                onChange={(e) => setNumRowsToAdd(parseInt(e.target.value))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button variant="text" size="small" color="primary" onClick={addMultipleItems}>
                        Add
                      </Button>
                      <Button
                        variant="text"
                        size="small"
                        color="error"
                        onClick={clearRows}
                        disabled={rows?.length === 0}
                      >
                        Clear
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item >
              <TextField
                label="Edit Row"
                value={newValue}
                onChange={handleValueChange}
                size="small"
                sx={{ flexGrow: 1 }}  // Increased width to accommodate both controls
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        value={selectedColumn}
                        onChange={handleColumnChange}
                        size="small"
                        variant="standard"
                        sx={{ 
                          mr: 1,
                          '&:before, &:after': { display: 'none' },
                          '& .MuiSelect-select': { 
                            py: 0,
                            minWidth: '120px'
                          }
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Column
                        </MenuItem>
                        {columns?.map((col:any) => (
                          col?.editable && (
                            <MenuItem key={col.field} value={col.field}>
                              {col.headerName}
                            </MenuItem>
                          )
                        ))}
                      </Select>
                      {selectedColumn === "proposal_price" && (
                        <Typography variant="body1" sx={{ fontWeight: 500, mr: 1 }}>
                          f(x) =
                        </Typography>
                      )}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <CustomButton size="small" color="primary" variant="text" onClick={updateSelectedRows}>
                        Update
                      </CustomButton>
                      <CustomButton size="small" variant="text" color="error" onClick={handleDeleteRow}>
                        Delete
                      </CustomButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid> 
        </Grid>

        
        <Grid item xs={12} sx={{ height: "auto", maxHeight: "60vh" }}>
          <DataGridPremium
            sx={{
              fontSize: 14,
              fontWeight: 500,
              border: "1px solid rgba(0,0,0,0.25)"
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  subjob_and_job_type: false,
                  job_type_id: false,
                  schedule_of_values_id: false,
                  document: false,
                  created_at: false,
                  created_by: false,
                  updated_at: false,
                  updated_by: false
                },
              },
            }}
            checkboxSelection
            rowSelection
            onRowSelectionModelChange={(selected: any) => setRowSelectionModel(selected)}
            apiRef={apiRef}
            editMode="row"
            getRowId={row => row?._id}
            density="compact"
            rows={rows || []}
            columns={columns || []}
            loading={isLoading}
            rowHeight={60}
            rowModesModel={rowModesModel}
            rowSelectionModel={rowSelectionModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onClipboardPasteStart={handlePasteStart}
            onClipboardPasteEnd={handlePasteEnd}
            ignoreValueFormatterDuringExport
          />
        </Grid>

        <Grid item xs={12} justifyContent="flex-end" sx={{ mt: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>

            {!completed[activeStep] &&
              <CustomButton size="small" sx={{ marginLeft: 1, fontWeight: 600 }} variant="contained" onClick={(event: any) => handleSubmit(event)} component="label" color="primary">
                Create items
              </CustomButton>}
            {completed[activeStep] &&
              <CustomButton size="small" sx={{ marginLeft: 1, fontWeight: 600 }} variant="contained" onClick={handleClose} component="label" color="primary">
                Close
              </CustomButton>}
          </Box>

        </Grid>

      </Grid>
    </form>
  );
};

export default Clipboard;
