
import metadataColumns from "../../components/columns/metadataColumns";
import { WIDTHS } from "../../../utils/columnUtil";
import Grid from "@mui/material/Grid/Grid";
import Link from "@mui/material/Link";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import { setSelectedItem } from "../../../features/projects/projectSlice";
import { useAppDispatch } from "../../../app/hooks";

const useCompanyColumns = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleNavigate = (row:any) => {
        dispatch(setSelectedItem(row));
        navigate(`/companies/${row._id}`);
    }
    const companyColumns:GridColDef[] = [
    {
        field: 'company_number',
        headerName: 'Co. No.',
        headerAlign: 'left',    
        align: 'left',
        type: 'number',
        width: WIDTHS.XS,
        editable: true,
        renderCell: (params:any) => (
            <Grid item xs={12} sx={{height: '100%', display: 'flex', alignItems: 'center'}}>
                <Link 
                    onClick={() => handleNavigate(params.row)}
                    sx={{ 
                        fontWeight: 600,
                        textDecoration: 'none',
                        '&:hover': { 
                            cursor: 'pointer',
                            textDecoration: 'underline' 
                        }
                    }}>
                    {params.value}
                </Link>
            </Grid>
        ),
    },
    {
        field: 'company_description',
        headerName: 'Description',
        headerAlign: 'left',    
        align: 'left',
        type: 'string',
        width: WIDTHS.LARGE,
        editable: true,
    },
    {
        field: 'company_principal',
        headerName: 'Principal',
        headerAlign: 'left',    
        align: 'left',
        type: 'string',
        width: WIDTHS.MEDIUM,
        editable: true,
    },
    {
        field: 'company_location',
        headerName: 'Location',
        headerAlign: 'left',    
        align: 'left',
        type: 'string',
        width: WIDTHS.MEDIUM,
        editable: true,
    },
    {
        field: 'company_revision_number',
        headerName: 'Revision',
        headerAlign: 'left',    
        align: 'left',
        type: 'string',
        width: WIDTHS.XS,
        editable: true,
    },
    {
        field: 'company_revision_description',
        headerName: 'Revision Description',
        headerAlign: 'left',    
        align: 'left',
        type: 'string',
        width: WIDTHS.LARGE,
        editable: true,
    },
    {
        field: 'company_revision_date',
        headerName: 'Revision Date',
        headerAlign: 'left',    
        align: 'left',
        type: 'date',
        width: WIDTHS.SMALL,
        editable: true,
        valueGetter: (value:any) => value ? new Date(value) : null,
    },
    {
        field: 'company_status',
        headerName: 'Status',
        headerAlign: 'left',    
        align: 'left',
        type: 'string',
        width: WIDTHS.SMALL,
        // Optional: Custom rendering or color based on status
        renderCell: (params:any) => (
            <span style={{ color: params.value === 'ACTIVE' ? 'green' : params.value === 'INACTIVE' ? 'orange' : 'red' }}>
                {params.value}
            </span>
        ),
        editable: true,
    },
    ...metadataColumns
    ];
    return companyColumns;
}

export default useCompanyColumns;